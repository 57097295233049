@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;500;600;700&amp;display=swap');

::-webkit-scrollbar{ width: 5px; }
::-webkit-scrollbar-track { background: #fff;}
::-webkit-scrollbar-thumb { background: #2a2a2a;border-radius: 0;}

* {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

body {
  font-family: 'Asap', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  overflow-x: hidden;
  background-color: #ffffff;
  color: #666666;
}

html, body, .wrapper {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: #232323;
  font-weight: normal;
  line-height: 1.2;
  font-family: 'Asap', sans-serif;
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 20px;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 27px;
}

h4 {
  font-size: 20px;
  /* background-color: #febc35; */
  height: 2rem;
    width: 8rem;
    text-align: center;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

img {
  border: none;
  outline: none;
  width: 100%;
}

label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%;
}

a, .btn, button {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  outline: medium none;
  font-weight: 600;
}

a img, iframe {
  border: none;
}

p {
  color: #666666;
  margin: 0 0 15px;
  text-transform: none;
  font-weight: 400;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eee;
}

pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  word-wrap: break-word;
  word-break: break-all;
  color: #333;
  border: 1px solid #ededed;
  border-radius: inherit;
  background-color: #f9f9f9;
  font-size: 13px;
  line-height: 1.42857143;
}

input:focus, textarea:focus, 
 select:focus {
  outline: none;
  box-shadow: inherit;
}

ul {
  margin: 0;
  list-style-type: none;
}

a, a:active, a:focus, a:hover {
  outline: none;
  text-decoration: none;
  color: #232323;
}

a:hover {
  color: #232323;
}

a {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  text-decoration: none;
  font-family: 'Asap', sans-serif;
}

b, strong {
  font-weight: 700;
}

.inc-border {
  border-top: 1px solid #e7e7e7;
}

.btn.active, .btn:active {
  background-image: inherit !important;
}

.btn.focus, 
 .btn:active:focus, 
 .btn:active:hover, 
 .btn:focus, 
 .btn:hover {
  outline: 0;
}

.btn.circle {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.btn.active, .btn:active {
  outline: 0;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.btn i {
  font-size: 12px;
  margin-left: 0;
  position: relative;
  top: 1px;
}

.video-btn {
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 10px;
}

.text-center .video-btn {
  margin-left: 0;
}

.video-btn i {
  display: inline-block;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  background: #febc35;
  border-radius: 50%;
  position: relative;
  margin-right: 20px;
  color: #ffffff;
}

.yellow .video-btn i {
  background: #febc35;
}

.text-light .video-btn i {
  background: #ffffff;
  color: #febc35;
}

.yellow .text-light .video-btn i {
  color: #232323;
}

.video-btn i::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #febc35 repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  z-index: -1;
}

.yellow .video-btn i::after {
  background: #febc35 repeat scroll 0 0;
}

input {
  border: 1px solid #e7e7e7;
  border-radius: inherit;
  box-shadow: inherit;
  min-height: 50px;
}
/* Default CSS */
.container-half {
  margin: 0 auto;
  width: 60%;
  padding: 0 15px;
}

.container-medium {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
  position: relative;
}

@media (min-width:576px) {
  .container-medium {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .container-medium {
    max-width: 720px;
  }
}

@media (min-width:992px) {
  .container-medium {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container-medium {
    max-width: 1400px;
    width: 80%;
  }
}

.bg-cover {
  background-position: center center;
  background-size: cover;
}

.bg-contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-fixed {
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.bg-gray {
  background-color: #eef1f6;
}

.bg-theme-small {
  background: rgba(255, 53, 20, 0.03);
}

.bg-light {
  background-color: #ffffff !important;
}

.bg-theme {
  background-color: #febc35;
}

.yellow .bg-theme {
  background-color: #febc35;
}

@media (min-width: 1200px) {
  .align-center {
    align-items: center;
  }
}

.text-light h1, 
 .text-light h2, 
 .text-light h3, 
 .text-light h4, 
 .text-light h5, 
 .text-light h6, 
 .text-light p, 
 .text-light a {
  color: #ffffff;
}

.shadow {
  position: relative;
  z-index: 1;
  box-shadow: inherit !important;
}

.shadow.dark::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.dark-hard::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.light::after {
  background: #ffffff none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.theme::after {
  background: #febc35 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.yellow .shadow.theme::after {
  background: #febc35 none repeat scroll 0 0;
}

.shadow.theme-hard::after {
  background: #febc35 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.yellow .shadow.theme-hard::after {
  background: #febc35 none repeat scroll 0 0;
}

.shadow.gradient-theme::after {
  background: linear-gradient(to bottom, #ff2d0c 0%,#ff7c68 100%);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.gradient-dark::after {
  background: linear-gradient(to bottom, #022147 0%,#2a3644 100%);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow-gradient-light::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: rgba(0, 0, 0, 0) linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.65) 100%) repeat scroll 0 0;
  height: 100%;
  width: 70%;
}

.default-padding {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

.default-padding-top {
  padding-top: 60px;
  position: relative;
}

.default-padding-top.bottom-less {
  margin-bottom: -30px;
}

.default-padding-bottom {
  padding-bottom: 120px;
  position: relative;
}

.default-padding-bottom.bottom-less {
  padding-bottom: 90px;
}

.default-padding.bottom-less {
  padding-top: 120px;
  padding-bottom: 90px;
}

.padding-xl {
  padding-top: 180px;
  padding-bottom: 180px;
}

button, button:focus {
  border: none !important;
  box-shadow: inherit !important;
  outline: inherit !important;
  cursor: pointer;
    width: 23rem;
    height: 4rem;
    background-color: #6c757d;
    font-weight: 900;
    color: white;
    background: #595959;
    border-bottom: 3px solid #464647;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    line-height: 29px;
    margin: 0px 0px 0px 0px;
    padding: 12px 0px 8px 20px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
}

.btn {
  display: inline-block;
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  letter-spacing: 1px;
  overflow: hidden;
  border-radius: inherit;
  border: none;
}

.btn.angle {
  position: relative;
}

.btn.angle::after {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -7px;
  content: "";
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 8px solid #232323;
  transition: all 0.35s ease-in-out;
}

.btn.angle.btn-sm::after {
  right: 27px;
}

.btn.angle.btn-theme::after {
  border-left-color: #ffffff;
}

.btn.angle.btn-light.effect:hover::after {
  border-left-color: #ffffff;
}

.btn-md {
  padding: 15px 40px;
}

.angle.btn-md {
  padding-right: 45px;
}

.btn-sm {
  padding: 10px 35px;
  font-size: 12px;
}

.angle.btn-sm {
  padding-right: 40px;
}

.btn.btn-sm-pro {
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
  padding: 4px 35px;
  display: inline-block;
}

.btn-border-light {
  border: 2px solid #ffffff;
}

.btn.btn-sm-pro.btn-border-light:hover, 
 .btn.btn-sm-pro.btn-border-light:focus {
  background-color: #ffffff;
  color: #232323;
  border: 2px solid #ffffff;
}

.btn-dark {
  background-color: #232323;
  color: #ffffff;
}

.btn-dark.border {
  background-color: transparent;
  color: #232323;
  border: 2px solid #232323 !important;
}

.btn-dark.border:hover {
  background-color: #232323;
  color: #ffffff !important;
  border: 2px solid #232323 !important;
}

.btn-light {
  color: #232323 !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-light::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 0;
  transition: all 0.25s ease-in-out;
  background: #febc35;
  z-index: -1;
}

.yellow .btn-light::after {
  background: #febc35;
}

.shadow .btn-light::after {
  background: #022147;
}

.btn-light:hover::after {
  width: 100%;
  height: 100%;
}

.shadow .btn-light:hover,
.btn-light:hover {
  color: #ffffff !important;
}

.btn.btn-light.border {
  background: transparent;
  border: 2px solid #ffffff !important;
  color: #ffffff;
}

.btn.btn-light.border:hover {
  background: #ffffff none repeat scroll 0 0 !important;
  border: 2px solid #ffffff !important;
  color: #232323 !important;
}

.btn-dark:hover, 
 .btn-dark:focus {
  color: #232323 !important;
  background: transparent;
}

.bg-dark {
  background: #022147 none repeat scroll 0 0 !important;
}

.bg-dark-hard {
  background: #00081d none repeat scroll 0 0;
}

.btn-theme {
  background-color: #febc35;
  color: #febc35 !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.yellow .btn-theme {
  background-color: #febc35;
  color: #000000 !important;
}

.yellow .btn-theme:hover {
  color: #ffffff !important;
}

.btn-theme::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 0;
  transition: all 0.25s ease-in-out;
  background: #022147;
  z-index: -1;
}

.shadow .btn-theme::after {
  background: #ffffff;
}

.btn-theme:hover::after {
  width: 100%;
  height: 100%;
}

.btn-theme.border {
  background-color: transparent;
  color: #febc35 !important;
  border: 2px solid #febc35;
}

.yellow .btn-theme.border {
  background-color: transparent;
  color: #232323 !important;
  border: 2px solid #febc35;
}

.btn-theme.border:hover {
  background-color: #febc35;
  color: #ffffff !important;
  border: 2px solid #febc35;
}

.btn-theme.border:hover {
  background-color: #febc35;
  color: #232323 !important;
  border: 2px solid #febc35;
}

.shadow .btn-theme:hover {
  color: #232323 !important;
  border-color: #ffffff;
}

.btn.btn-sm-lm {
  font-size: 12px;
  padding: 4px 35px;
}

.bg-theme a.btn-theme.btn:hover,
.bg-theme a.btn-theme.btn:focus {
  border: 2px solid #ffffff;
  color: #ffffff;
}

a.more-btn {
  text-transform: uppercase;
  color: #666666;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  z-index: 1;
}

a.more-btn > i {
  font-size: 10px;
  position: relative;
  top: -1px;
}

a.more-btn::after {
  position: absolute;
  left: 0;
  bottom: -5px;
  content: "";
  height: 1px;
  width: 40px;
  border-bottom: 1px dashed;
  transition: all 0.35s ease-in-out;
}

a.more-btn:hover::after {
  width: 100%;
}

a.more-btn:hover {
  color: #febc35;
}

.yellow a.more-btn:hover {
  color: #232323;
}

.bg-fixed {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}

.bg-cover {
  background-position: center center;
  background-size: cover;
}

.container-full {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

@media (min-width:576px) {
  .container-full {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .container-full {
    max-width: 720px;
  }
}

@media (min-width:992px) {
  .container-full {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container-full {
    max-width: 1400px;
    width: 90%;
  }
}

.site-heading h2 {
  display: inline-block;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 0;
  position: relative;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.bg-dark .site-heading h2,
.bg-dark .site-heading p {
  color: #ffffff;
}

.site-heading.less-info h2 {
  margin-bottom: 0;
}

.site-heading h4 {
  color: #febc35;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 10px;
}

.yellow .site-heading h4 {
  color: #666666;
}

.site-heading.clean h2 {
  padding-bottom: 0;
}

.site-heading.clean h2 span {
  color: #febc35;
}

.yellow .site-heading.clean h2 span {
  color: #febc35;
}

.site-heading h2 span {
  color: #febc35;
}

.yellow .site-heading h2 span {
  color: #febc35;
}

.site-heading p {
  margin: 0;
  margin-top: 20px;
  padding: 0 10%;
}

.site-heading h2::before {
  background: #febc35 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 40px;
}

.yellow .site-heading h2::before {
  background: #febc35 none repeat scroll 0 0;
  height: 3px;
}

.site-heading h2::after {
  background: #febc35 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: 20px;
  position: absolute;
  width: 5px;
}

.yellow .site-heading h2::after {
  background: #febc35 none repeat scroll 0 0;
  height: 3px;
}

.site-heading.text-left h2::before {
  left: 0;
  margin-left: 0;
}

.site-heading.text-left h2::after {
  left: 45px;
  margin-left: 0;
}

.shadow .site-heading h2, 
.shadow .site-heading p {
  color: #ffffff;
}

.bg-theme .site-heading h2::before,
.shadow .site-heading h2::before,
.half-bg .site-heading h2::before {
  background: #ffffff none repeat scroll 0 0;
}

.site-heading h2 span {
  color: #febc35;
}

.yellow .site-heading h2 span {
  color: #febc35;
}

.site-heading {
  margin-bottom: 60px;
}

.site-heading.single {
  margin-bottom: 35px;
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px 0;
  padding: 15px;
}

.relative {
  position: relative;
  z-index: 1;
}

/* Shape */

.shape-box {
  position: relative;
  z-index: 1;
}

.shape-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.shape-bg img {
  width: 100%;
}

/* Side BG */
.side-bg {
  height: 100%;
  position: absolute;
  left: 30px;
  top: 0;
  width: 35%;
}

.side-bg.left {
  left: 30px;
  right: auto;
}

.side-bg img {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.animated.side-bg {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: infiUpDown;
  animation-name: infiUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@keyframes infiUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes infiUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}



/* ============================================================== 
     # Topbar 
=================================================================== */

.top-bar-area {
  overflow: hidden;
  padding: 12px 0;
}

.top-bar-area .shape {
  position: relative;
  z-index: 1;
}

.top-bar-area .shape::after {
  position: absolute;
  right: 0;
  top: -100%;
  content: "";
  height: 300%;
  width: 500%;
  background: #febc35;
  z-index: -1;
  transform: skewX(20deg);
  border-right: 5px solid #ffffff;
}

.yellow .top-bar-area .shape::after {
  background: #febc35;
}

.top-bar-area .social li {
  margin-right: 20px;
}

.top-bar-area .social li:last-child {
  margin-right: 0;
}

.top-bar-area li {
  display: inline-block;
}

.top-bar-area .text-left .info {
  display: flex;
  justify-content: flex-start;
}

.top-bar-area .float-right .info {
  display: flex;
  justify-content: flex-end;
}

.top-bar-area .info > ul  li {
  display: inline-block;
  align-items: center;
  text-align: left;
}

.top-bar-area .info > ul  li a {
  font-weight: 400;
}

.top-bar-area .info > ul  li a:hover {
  color: #febc35;
}

.yellow .top-bar-area .info > ul  li a:hover {
  color: #febc35;
}

.top-bar-area .float-right .info > ul {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #e7e7e7;
}

.top-bar-area .text-left .info > ul {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #e7e7e7;
}

.top-bar-area.bg-dark .float-right .info > ul {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar-area.bg-dark .text-left .info > ul {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar-area .float-right .info > ul:first-child {
  margin: 0;
  padding: 0;
  border: none;
}

.top-bar-area .text-left .info > ul:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

.top-bar-area .float-right .info .list li {
  margin-left: 20px;
}

.top-bar-area .text-left .info .list li {
  margin-right: 20px;
}

.top-bar-area .text-left .info .list li:last-child {
  margin-right: 0;
}

.top-bar-area .info .list li i {
  color: #febc35;
  margin-right: 5px;
  font-size: 20px;
  position: relative;
  top: 1px;
}

.yellow .top-bar-area .info .list li i {
  color: #febc35;
}

.top-bar-area.bg-dark .float-right .social a {
  margin-left: 15px;
}

.top-bar-area.bg-dark .text-left .social a {
  margin-right: 15px;
}

.top-bar-area.bg-dark .float-right .social li:first-child a {
  margin: 0;
}

.top-bar-area.bg-dark .text-left .social li:last-child a {
  margin: 0;
}

.top-bar-area .item-flex {
  display: flex;
  align-items: center;
}

.top-bar-area .item-flex ul {
  margin-left: 30px;
  padding-left: 30px;
  position: relative;
  z-index: 1;
}

.top-bar-area .item-flex ul li {
  margin-left: 15px;
}

.top-bar-area .item-flex ul::after {
  position: absolute;
  left: 0;
  top: 6px;
  content: "";
  height: 20px;
  width: 1px;
  background: rgba(255, 255, 255, 0.3);
}

.top-bar-area .item-flex ul:first-child {
  margin: 0;
  padding: 0;
}

.top-bar-area .item-flex ul:first-child::after {
  display: none;
}

.top-bar-area .item-flex ul li:first-child {
  margin: 0;
}

.top-bar-area .text-right .button {
  position: relative;
  z-index: 1;
}

.top-bar-area .text-right .button::after {
  position: absolute;
  left: 0;
  top: -35px;
  content: "";
  height: 100px;
  width: 200%;
  background: #febc35;
  z-index: -1;
  transform: skewX(-30deg);
  border-left: 3px solid #ffffff;
}

.yellow .top-bar-area .text-right .button::after {
  background: #febc35;
}

.top-bar-area .text-right .button a {
  font-weight: 600;
  display: inline-block;
}


/* ============================================================== 
     # Bradcrumb 
=================================================================== */
.breadcrumb-area {
  padding: 150px 0;
}

.breadcrumb-area .breadcrumb {
  background: transparent none repeat scroll 0 0;
  border: none;
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 1;
  padding: 0;
}

.breadcrumb-area h1 {
  display: block;
  font-weight: 600;
  margin-top: -10px;
}

.breadcrumb > li + li::before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  padding: 0 5px;
  color: #ffffff;
  display: none;
}

.breadcrumb-area .breadcrumb li i {
  margin-right: 5px;
}

.breadcrumb-area .breadcrumb li {
  padding: 0 15px;
  position: relative;
  display: inline-block;
  z-index: 1;
  font-weight: 700;
}

.breadcrumb-area .breadcrumb li::after {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  left: -5px;
  position: absolute;
  font-size: 18px;
}

.breadcrumb-area .breadcrumb li:first-child::after {
  display: none;
}

.breadcrumb-area .breadcrumb a, 
.breadcrumb-area .breadcrumb li {
  font-family: 'Asap', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}

/* ============================================================== 
     # Banner 
=================================================================== */
body, 
.banner-area, 
.banner-area div {
  height: 100vh;
}

.banner-area.auto-height, 
.banner-area.auto-height div {
  height: auto;
  min-height: 100%;
}

.banner-area .box-cell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

table, tr, td {
  padding: 15px;
  text-align: left;
}

/* table{
  border: 1px solid black;
} */

.banner-area .box-table {
  display: table;
  width: 100%;
}

.banner-area .box-cell, 
 .banner-area .box-cell div {
  height: auto;
}

.banner-area {
  position: relative;
  overflow: hidden;
}

.banner-area .wavesshape {
  bottom: -2px;
  left: 0;
  position: absolute;
  right: 0;
  top: auto;
  width: 100%;
}

.banner-area .wavesshape.shape {
  bottom: -50px;
  height: auto;
}

.banner-area .content {
  position: relative;
  z-index: 9;
}

.banner-area.wavesshape {
  position: relative;
  z-index: 1;
}

.banner-area.triangle-shadow {
  position: relative;
  z-index: 1;
}

.banner-area.triangle-shadow::before {
  border-bottom: 0 solid transparent;
  border-right: 100vw solid #fff;
  border-top: 80px solid transparent;
  bottom: 0;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 101;
}

.banner-area.shadow-inner .content {
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-area.content-double .double-items {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.banner-area .banner-carousel .owl-item.center img {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.banner-area .banner-carousel .owl-item img {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

/* Animation delays */
.banner-area .carousel-item h1:first-child, 
 .banner-area .carousel-item h2:first-child, 
 .banner-area .carousel-item h3:first-child, 
 .banner-area .carousel-item h4:first-child, 
 .banner-area .carousel-item h5:first-child, 
 .banner-area .carousel-item h6:first-child {
  animation-delay: .5s;
}

.banner-area .carousel-item h1:nth-child(2), 
 .banner-area .carousel-item h2:nth-child(2), 
 .banner-area .carousel-item h3:nth-child(2), 
 .banner-area .carousel-item h4:nth-child(2), 
 .banner-area .carousel-item h5:nth-child(2), 
 .banner-area .carousel-item h6:nth-child(2) {
  animation-delay: .7s;
}

.carousel-caption h1:nth-child(3), 
 .carousel-caption h2:nth-child(3), 
 .carousel-caption h3:nth-child(3), 
 .carousel-caption h4:nth-child(3), 
 .carousel-caption h5:nth-child(3), 
 .carousel-caption h6:nth-child(3) {
  animation-delay: .9s;
}

.banner-area .carousel-item p {
  animation-delay: .5s;
}

.banner-area .carousel-item ul {
  animation-delay: .6s;
}

.banner-area .carousel-item a, 
 .banner-area .carousel-item button {
  animation-delay: .7s;
}

/* Carousel Fade Effect */
.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .item, 
 .carousel-fade .carousel-inner .active.left, 
 .carousel-fade .carousel-inner .active.right {
  opacity: 0;
}

.carousel-fade .carousel-inner .active, 
 .carousel-fade .carousel-inner .next.left, 
 .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-inner .next, 
 .carousel-fade .carousel-inner .prev, 
 .carousel-fade .carousel-inner .active.left, 
 .carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-control {
  z-index: 2;
}
/* Slider Zoom Effect */
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.2, 1.2);
  }
}

@-moz-keyframes zoom {
  from {
    -moz-transform: scale(1, 1);
  }

  to {
    -moz-transform: scale(1.2, 1.2);
  }
}

@-o-keyframes zoom {
  from {
    -o-transform: scale(1, 1);
  }

  to {
    -o-transform: scale(1.2, 1.2);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.2, 1.2);
  }
}

.carousel-inner.carousel-zoom .carousel-item > .slider-thumb {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}

.banner-area .carousel-zoom .slider-thumb {
  height: 100%;
  position: absolute;
  width: 100%;
}

#particles-js,
#ripple {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.banner-area .carousel-control {
  background: transparent none repeat scroll 0 0;
  font-size: 40px;
  height: 50px;
  line-height: 50px;
  margin-top: -25px;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 1;
  color: #ffffff;
  display: inline-block;
  text-align: center;
}

.banner-area .carousel-control.dark {
  color: #232323;
}

.banner-area .carousel-control.theme {
  color: #febc35;
}

.yellow .banner-area .carousel-control.theme {
  color: #febc35
}

.yellow .banner-area.text-large .carousel-control.theme {
  color: #000000
}

.banner-area .carousel-control.light {
  color: #ffffff;
}

.banner-area .carousel-control.shadow {
  background: transparent none repeat scroll 0 0;
  color: #ffffff;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  margin-top: -25px;
  opacity: 1;
  padding: 0;
  position: absolute;
  text-shadow: inherit;
  top: 50%;
  width: 50px;
  z-index: 1;
}

.banner-area .carousel-control.shadow::after {
  background: #1e2726 none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.banner-area .carousel-control.left {
  left: -80px;
}

.banner-area:hover .carousel-control.left {
  left: 20px;
}

.banner-area:hover .carousel-control.shadow.left {
  left: 20px;
}

.banner-area .carousel-control.right {
  right: -80px;
}

.banner-area:hover .carousel-control.right {
  right: 20px;
}

.banner-area:hover .carousel-control.shadow.right {
  right: 20px;
}

.banner-area .carousel-control i {
  font-weight: 400;
}

/* Carousel Indicators */

.banner-area .carousel-indicator {
  position: absolute;
  right: 80px;
  width: auto;
}

.banner-area .carousel-indicators {
  position: absolute;
  right: auto;
  left: 0;
  width: auto;
  height: auto !important;
  top: 50%;
  bottom: inherit;
  margin: 0;
  padding: 30px 0;
  display: block;
  transform: translateY(-50%);
}

.banner-area .carousel-indicators.right {
  left: auto;
  right: 50px;
  height: auto;
  bottom: auto;
}

.banner-area .carousel-indicators li {
  display: block;
  height: 20px;
  width: 20px;
  margin: 10px 5px;
  border: 6px solid #febc35;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: transparent;
  position: relative;
  z-index: 1;
}

.yellow .banner-area .carousel-indicators li {
  border: 6px solid #febc35;
}

.banner-area .carousel-indicators.theme li {
  border: 2px solid #e7e7e7;
  opacity: 1;
}

.banner-area .carousel-indicators li.active {
  border: 6px solid #febc35;
}

.yellow .banner-area .carousel-indicators li.active {
  border: 6px solid #febc35;
}

.banner-area .carousel-indicators.theme li.active {
  border: 6px solid #febc35;
}

.yellow .banner-area .carousel-indicators.theme li.active {
  border: 6px solid #febc35;
}

.banner-area .carousel-indicators li::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #febc35;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.yellow .banner-area .carousel-indicators li::after {
  background: #febc35;
}

.banner-area .carousel-indicators.theme li::after {
  background: #febc35;
}

.yellow .banner-area .carousel-indicators.theme li::after {
  background: #febc35;
}

.banner-area.video-bg-live .mbYTP_wrapper {
  z-index: 1 !important;
}

.banner-area .fixed-thumb .slider-thumb {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40%;
}

.banner-area .content a {
  margin-right: 5px;
  margin-top: 5px;
}

.banner-area.thumb-half .slider-thumb {
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
}

.banner-area.top-pad-60 .content {
  padding-top: 60px;
}

.banner-area .fixed-video {
  position: absolute;
  left: 200px;
  bottom: 0;
  padding: 51px 100px;
  height: auto;
  background: #022147;
  display: inline-block;
  width: 100px;
  z-index: 1;
}

.banner-area .fixed-video .video-play-button.relative {
  position: absolute;
  display: inline-block;
  left: 50%;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  margin-top: 0 !important;
  top: 50%;
}

.banner-area .fixed-video .video-play-button.relative::before, 
.banner-area .fixed-video .video-play-button.relative::after {
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.banner-area .fixed-video .video-play-button i {
  display: block;
  position: relative;
  z-index: 3;
  top: 19px;
  font-size: 20px;
}

/* Content */

.banner-area.inc-shape .content .shape {
  position: absolute;
  width: 350px;
  height: 450px;
  border: 10px solid rgba(255, 255, 255, 0.1);
  left: -55px;
  top: 50%;
  z-index: -1;
  transform: translate(0, -50%);
}

.banner-area.inc-shape .text-center .content .shape {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.banner-area.text-large .content h2 {
  font-size: 80px;
  font-weight: 900;
  margin-bottom: 30px;
  line-height: 1;
  text-transform: capitalize;
  color: black;
}

.banner-area .content ul li {
  display: flex;
  align-items: center;
  float: left;
  margin-right: 30px;
}

.banner-area .content ul {
  overflow: hidden;
  margin-bottom: 30px;
  display: inline-block;
  color: #232323;
}

.banner-area.title-top .content ul {
  margin-bottom: 15px;
}

.banner-area .content ul li h5 {
  margin: 0;
  text-transform: capitalize;
  font-weight: 600;
}

.banner-area .content ul li i {
  display: inline-block;
  font-size: 40px;
  margin-right: 15px;
  color: #febc35;
}

.yellow .banner-area .content ul li i {
  color: #febc35;
}

.banner-area .carousel-item {
  position: relative;
}

.banner-area.inc-fixed-thumb .carousel-item .fixed-thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 40%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.banner-area.text-medium .content h2 {
  font-size: 60px;
  font-weight: 200;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.banner-area.text-medium .content h2 strong {
  display: block;
  font-size: 100px;
  line-height: 1;
  text-transform: uppercase;
  margin-top: 15px;
}

.banner-area .content p {
  padding-right: 20%;
  font-size: 16px;
  line-height: 32px;
}

.banner-area.text-center .content p,
.banner-area .text-center .content p {
  padding-right: 10%;
  padding-left: 10%;
}

.banner-area.text-right .content p,
.banner-area .text-right .content p {
  padding-right: 0;
  padding-left: 15%;
}

.banner-area.inc-content .content a {
  margin-top: 20px;
}

.banner-area.default .content h4 {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
}

.banner-area.text-large .content h3 {
  text-transform: uppercase;
  font-size: 20px;
  color: #666;
  font-weight: 600;
  margin-bottom: 25px;
}

.banner-area.default .content h4::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 10px;
  width: 100%;
  background: #febc35;
  z-index: -1;
}

.yellow .banner-area.default .content h4::after {
  background: #febc35;
}

.banner-area.default .content h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 66px;
  margin-bottom: 25px;
}

.banner-area.shape .box-cell {
  position: relative;
  z-index: 1;
}

.banner-area.shape .box-cell::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 62%;
  background: #000000;
  z-index: -1;
  transform: skew(30deg);
  opacity: 0.5;
  margin-left: -15%;
}

.banner-area.shape .box-cell::before {
  position: absolute;
  left: 47%;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100px;
  background: #febc35;
  z-index: -1;
  transform: skew(30deg);
  opacity: 0.7;
}

.yellow .banner-area.shape .box-cell::before {
  background: #febc35;
}

.banner-area.shape .shadow.dark::after {
  opacity: 0.3;
}

.banner-area.text-large .content .shape {
  position: absolute;
  left: -80px;
  top: -20%;
  content: "";
  height: 140%;
  width: 80%;
  background: rgba(255, 255, 255, 0.7);
  z-index: -1;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  clip-path: polygon(50% 0%, 100% 100%, 0 100%, 0 0);
}

.banner-area .carousel-item .right-thumb {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 48%;
  background-position: center center;
  top: 0;
  right: 0;
}

@media only screen and (min-width: 992px) {
  .banner-area .carousel-item .right-thumb::after {
    position: absolute;
    left: -50px;
    top: 0;
    content: "";
    height: 100%;
    width: 120px;
    background: #eef1f6;
    transform: skewX(-5deg);
  }
}

.banner-area.top-pad-90 .content {
  padding-top: 90px;
}

.banner-area.text-default h3 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;
}

.banner-area.text-default h2 {
  font-size: 65px;
  font-weight: 900;
  line-height: 1.1;
  margin: 0;
}

.banner-area.text-default h2 strong {
  font-weight: 900;
  color: #febc35;
}

.yellow .banner-area.text-default h2 strong {
  color: #febc35;
}

.banner-area.text-default p {
  margin-top: 30px;
}

.banner-area.text-default a {
  margin-top: 15px;
}

@media only screen and (min-width: 1200px) {
  .banner-area.border-shape .content::after {
    position: absolute;
    left: -120px;
    top: 50%;
    content: "";
    height: 500px;
    width: 400px;
    border: 60px solid #febc35;
    z-index: -1;
    opacity: 0.4;
    transform: translateY(-50%);
    margin-top: 50px;
  }

  .yellow .banner-area.border-shape .content::after {
    border: 60px solid #febc35;
  }
}

/* ============================================================== 
     # Video Button Play
=================================================================== */
.video-play-button {
  color: #febc35;
  font-size: 30px;
  left: 50%;
  padding-left: 7px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.yellow .video-play-button {
  color: #febc35;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.video-play-button i {
  display: block;
  position: relative;
  z-index: 3;
  color: #febc35;
}

.yellow .video-play-button i {
  color: #232323;
}


/* ============================================================== 
     # About 
=================================================================== */

.about-area .fixed-shape {
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
  text-align: right;
}

.about-area.ver-two .fixed-shape {
  right: auto;
  left: 0;
  top: 120px;
  text-align: left;
}

.about-area .info-content {
  padding-right: 35px;
}

.about-area .info-content h2 {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-weight: 600;
  line-height: 1.3;
  font-size: 28px;
}

.about-area .info-content h2 strong {
  font-size: 140px;
  line-height: 110px;
  padding-right: 20px;
  color: #febc35;
  font-weight: 800;
}

.yellow .about-area .info-content h2 strong {
  color: #febc35;
}

.about-area blockquote {
  font-size: 14px;
  border-left: 2px solid #febc35;
  margin-top: 30px;
  margin-bottom: 0;
  padding: 0;
  padding-left: 25px;
}

.yellow .about-area blockquote {
  border-left: 2px solid #febc35;
}

.about-area .right-info blockquote {
  margin-top: 0;
}

.about-area .right-info span {
  margin-top: 25px;
}

.about-area .left-info h2 {
  line-height: 1.4;
  font-weight: 600;
  margin-top: -5px;
  margin-bottom: 0;
}

.about-area .left-info h2 strong {
  font-weight: 600;
  color: #febc35;
}

.yellow .about-area .left-info h2 strong {
  color: #febc35;
}

.about-area .info img {
  height: 50px;
  margin-top: 10px;
}

.about-area .info-content > a {
  margin-top: 30px;
}

.about-area blockquote span {
  display: block;
  font-family: 'Asap', sans-serif;
  text-transform: uppercase;
  color: #232323;
}

.about-area blockquote span strong {
  color: #febc35;
}

.yellow .about-area blockquote span strong {
  color: #febc35;
}

.about-area .thumb {
  position: relative;
  z-index: 1;
  text-align: left;
}

.about-area .thumb .video {
  position: absolute;
  right: -120px;
  bottom: -50px;
  background: #ffffff;
  box-shadow: 0 5px 30px 0 rgb(214 215 216 / 57%);
  height: 70%;
  width: 250px;
  border: 4px solid #ffffff;
  background-position: center;
  border-radius: 30px;
}

.about-area .thumb .video .video-play-button:before {
  width: 60px;
  height: 60px;
  background: #febc35;
}

.yellow .about-area .thumb .video .video-play-button:before {
  background: #febc35;
}

.about-area .thumb .video .video-play-button:after {
  width: 60px;
  height: 60px;
  background: #febc35;
}

.yellow .about-area .thumb .video .video-play-button:after {
  background: #febc35;
}

.about-area .thumb .video .video-play-button i {
  color: #ffffff;
}

.about-area.ver-two .info {
  padding-left: 50px;
}

.about-area .establish {
  display: flex;
  margin-bottom: 25px;
}

.about-area .establish i {
  display: inline-block;
  font-size: 60px;
  margin-right: 20px;
  color: #febc35;
}

.yellow .about-area .establish i {
  color: #febc35;
}

.about-area .establish p {
  font-size: 20px;
  margin: 0;
  line-height: 30px;
  color: #232323;
  font-weight: 500;
}

/* Features */
.about-area .content-box .item {
  padding: 30px;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  text-align: center;
}

.about-area .content-box .equal-height:first-child, 
.about-area .content-box .equal-height:nth-child(2) {
  margin-top: 0;
}

.about-area .content-box .item > i {
  display: block;
  font-size: 40px;
  margin-bottom: 30px;
  color: #febc35;
  position: relative;
  z-index: 1;
  padding-bottom: 30px;
}

.yellow .about-area .content-box .item > i {
  color: #febc35;
}

.about-area .content-box .item > i::after {
  position: absolute;
  left: -25%;
  bottom: 0;
  content: "";
  height: 1px;
  width: 150%;
  background: #e7e7e7;
}

.about-area .content-box .equal-height {
  margin-top: 30px;
}

.about-area .content-box .item h4 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 20px;
}

.about-area .content-box .item p {
  margin: 0;
}

.about-area .content-box .item a {
  text-transform: uppercase;
  font-weight: 600;
  color: #666666;
  font-size: 12px;
  letter-spacing: 1px;
}

.about-area .content-box .item a i {
  font-size: 10px;
}

.about-area .experience {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.about-area .experience h3 {
  font-size: 60px;
  border-right: 1px solid #cccccc;
  margin-right: 25px;
  padding-right: 30px;
  margin-bottom: 0;
  font-weight: 900;
}

.about-area .experience h3 strong {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.about-area .experience .author {
  display: flex;
  align-items: center;
}

.about-area .experience .author h5 {
  margin-bottom: 4px;
  font-weight: 600;
}

.about-area .experience .author img {
  margin-top: 0;
  margin-right: 15px;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: -1px 3px 21px 0 rgb(0 0 0 / 6%);
}

.about-area.ver-two .info .text-opacity {
  position: absolute;
  font-size: 200px;
  text-transform: uppercase;
  z-index: -1;
  font-weight: 900;
  line-height: 140px;
  top: -54px;
  opacity: 0.03;
  left: -50px;
}

.about-area.ver-two .info .text-opacity.little {
  font-size: 150px;
  top: -70px;
}

.about-area.ver-two {
  padding-bottom: 20px;
  margin-bottom: -20px;
}

.about-area.ver-two .thumb {
  margin-bottom: -20px;
  margin-top: -10px;
}

/* ============================================================== 
     # About Version Two
=================================================================== */

.about-area .thumb-left .thumb .experiecne {
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 50px;
  background:#febc35;
  padding: 50px 30px;
  border: 5px solid #ffffff;
  max-width: 250px;
}

.yellow .about-area .thumb-left .thumb .experiecne {
  background: #febc35;
}

.about-area .thumb-left .thumb .experiecne h2 {
  font-size: 70px;
  font-weight: 700;
  line-height: 55px;
  color: #ffffff;
}

.about-area .thumb-left .thumb img {
  width: 80%;
}

.about-area .thumb-left .thumb .experiecne h5 {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 500;
}

.about-area .thumb-left {
  padding-right: 35px;
}

.about-area .thumb-left .thumb {
  margin-bottom: 0;
}

.about-area .thumb-left .thumb {
  position: relative;
  z-index: 1;
  text-align: right;
}

.about-area .thumb-left .thumb::after {
  position: absolute;
  left: -50px;
  top: -50px;
  content: "";
  height: 300px;
  width: 300px;
  background: url(assets/img/pattern.png);
  background-repeat: no-repeat;
  background-position: left top;
  opacity: 0.4;
  z-index: -1;
}

.about-area .info .bottom {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.about-area .info .bottom .icon a {
  display: flex;
  align-items: center;
  margin-right: 35px;
}

.about-area .info .bottom .icon a span {
  font-weight: 400;
  color: #66666;
}

.about-area .info .bottom .icon i {
  display: inline-block;
  background: #022147;
  color: #ffffff;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border-radius: 50%;
  margin-right: 15px;
}

.about-area .info .bottom .icon i.fa-phone-volume {
  transform: rotate(-45deg);
  position: relative;
}

.about-area .info .bottom .icon i.fa-phone-volume::before {
  left: 2px;
  position: relative;
  top: 2px;
}

.about-area .info .bottom .icon h4 {
  margin-bottom: 5px;
  font-weight: 700;
}

.about-area .info h2 {
  font-weight: 700;
  margin-bottom: 25px;
}

.about-area .info ul {
  display: block;
  margin-top: 25px;
}

.about-area .info ul li {
  display: block;
  line-height: 36px;
  padding-left: 30px;
  position: relative;
  z-index: 1;
  font-weight: 600;
}

.about-area .info ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #febc35;
}

.yellow .about-area .info ul li::after {
  color: #febc35;
}

.about-area .info > a.btn {
  margin-top: 30px;
}


/* ============================================================== 
     # About Version Two
=================================================================== */

.about-simple-area .right-info p {
  margin-bottom: 0;
  margin-top: 15px;
}

.about-simple-area .right-info p:first-child {
  margin-top: 0;
}

.about-simple-area ul.achivement li .counter {
  margin-bottom: 15px;
}

.about-simple-area .left-info h2 {
  font-weight: 600;
}

.about-simple-area .right-info blockquote {
  font-size: 18px;
  font-weight: 600;
  color: #232323;
  line-height: 30px;
}

.about-simple-area .contact {
  align-items: center;
  margin-top: 40px;
  display: inline-block;
  background: #f4f5f8;
  padding: 20px 40px;
  position: relative;
  z-index: 1;
}

.about-simple-area .contact i {
  display: inline-block;
  font-size: 45px;
  color: #febc35;
  text-align: center;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  margin-right: 20px;
}

.yellow .about-simple-area .contact i {
  color: #febc35;
}

.about-simple-area .contact i.fa-phone-volume {
  transform: rotate(-49deg);
}

.about-simple-area .contact h4 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 24px;
}

.about-simple-area .contact span {
  font-weight: 400;
  color: #666666;
}

.thumb-services-area {
  position: relative;
  z-index: 1;
}

.about-simple-area .contact i.fa-phone-volume::before {
  position: relative;
  left: 0;
  top: 5px;
}

.about-simple-area .contact span {
  font-weight: 500;
}

.about-simple-area .contact .icon, 
.about-simple-area .contact .content {
  float: left;
}

.about-simple-area .contact::after {
  position: absolute;
  left: 0;
  top: -16px;
  content: "";
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 16px solid #f4f5f8;
}

.about-area .info ul.achivement li {
  padding-left: 0;
  display: inline-block;
  line-height: 24px;
}

.about-area .info ul.achivement li::after {
  display: none;
}

.about-area .info ul.achivement {
  overflow: hidden;
  margin-top: 30px;
}

.about-area .info ul.achivement li span {
  margin-top: 15px;
  display: block;
  text-align: left;
}

/* ============================================================== 
     # Features
=================================================================== */

.features-area .features-items {
  padding: 70px 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -120px;
  z-index: 9;
  background: #ffffff;
  margin-bottom: -120px;
}

.features-area .features-items .item {
  padding: 0 30px;
}

.features-area .features-items .item p {
  margin: 0;
}

.features-area .features-items .item i {
  display: inline-block;
  font-size: 50px;
  margin-bottom: 30px;
  color: #febc35;
  position: relative;
  z-index: 1;
}

.yellow .features-area .features-items .item i {
  color: #febc35;
}

.features-area .features-items .item h5 {
  line-height: 1.4;
  font-weight: 600;
  font-size: 17px;
}

.features-area .features-items .quotes i {
  color: #e7e7e7;
}

.features-area .features-items .item h5 a {
  font-weight: 600;
}

.features-area .quotes .author {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
  margin-top: 25px;
}

.features-area .quotes .author img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.features-area .quotes .author h5 {
  margin-bottom: 0;
}

.features-area .features-items .single-item.emergency {
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.features-area .features-items .single-item.emergency::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #000000;
  z-index: -1;
  opacity: 0.6;
  border-radius: 10px;
}

.features-area .features-items .single-item.emergency .item {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 50px;
  text-align: center;
  justify-content: center;
}

.features-area .features-items .single-item.emergency i {
  display: block;
  margin-top: 0;
  margin-bottom: 30px;
  color: #ffffff;
}

.features-area .features-items .single-item.emergency p {
  margin-bottom: 20px;
}

.features-area .features-items .single-item.emergency h2 {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 22px;
}

/* ============================================================== 
     # Feature Version Two Area
=================================================================== */

.our-features-area .feature-item {
  background: #022147;
}

.our-features-area .feature-item .info:last-child {
  background: #febc35;
}

.yellow .our-features-area .feature-item .info:last-child {
  background: #febc35;
}

.our-features-area .feature-item .info {
  display: flex;
  align-items: center;
  padding: 120px 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.our-features-area .feature-item .info::after {
  position: absolute;
  right: -100px;
  bottom: -50px;
  content: "";
  height: 250px;
  width: 250px;
  background: #ffffff;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.15;
}

.our-features-area .feature-item .info:last-child::after {
  opacity: 0.3;
  right: auto;
  left: -100px;
}

.our-features-area .feature-item .thumb {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.our-features-area .feature-item .info h2 {
  font-weight: 600;
}

.our-features-area .feature-item .info h2 strong {
  display: block;
  font-size: 150px;
  -webkit-text-stroke: 1px #ffffff;
  color: transparent;
  margin-bottom: -10px;
}

.yellow .our-features-area .feature-item .info:last-child h2 {
  color: #000000;
}

/* ============================================================== 
     # Consulting Area
=================================================================== */

.consulting-area .row {
  margin: 0;
}

.consulting-area {
  position: relative;
  z-index: 1;
}

.consulting-area::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: #eef1f6;
  z-index: -1;
}

.consulting-area.bg-gray::after {
  background: #ffffff;
}

.consulting-area .inner-items {
  background: #ffffff;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.consulting-area .inner-items .left-info {
  padding: 120px 50px;
  padding-left: 0;
  position: relative;
  z-index: 1;
}

.consulting-area .inner-items .left-info::before {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 300%;
  background: #febc35;
  z-index: -1;
}

.yellow .consulting-area .inner-items .left-info::before {
  background: #febc35;
}

.consulting-area .inner-items .left-info::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 300px;
  width: 400px;
  background: url(assets/img/shape/2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: -1;
}

.consulting-area .inner-items .left-info .item {
  margin-top: 30px;
}

.consulting-area .inner-items .left-info .item:first-child {
  margin-top: 0;
}

.consulting-area .inner-items .left-info .item h4 {
  font-weight: 600;
}

.consulting-area .inner-items .left-info .item p {
  margin: 0;
}

ul.achivement {
  margin-top: 35px;
  display: block;
}

ul.achivement li {
  display: inline-block;
  margin-right: 40px;
  border-right: 1px solid #cccccc;
  padding-right: 40px;
  float: left;
}

.text-light ul.achivement li {
  border-right: 1px solid rgba(255, 255, 255, 0.6);
}

ul.achivement li:last-child {
  padding: 0;
  margin: 0;
  border: none;
}

ul.achivement li .counter {
  position: relative;
}

ul.achivement li .counter {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 50px;
  font-weight: 700;
  color: #232323;
  line-height: 1;
  margin-bottom: 5px;
}

.text-light ul.achivement li .counter {
  color: #ffffff;
}

ul.achivement li .counter .operator {
  font-size: 40px;
  margin-left: 2px;
}

ul.achivement li .medium {
  font-weight: 500;
  text-align: right;
}

.consulting-area .inner-items .right-info {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
  padding: 50px;
}

.consulting-area .inner-items .right-info::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #000000;
  z-index: -1;
  opacity: 0.5;
}

.consulting-area .inner-items .right-info h2 {
  color: #ffffff;
  font-weight: 700;
}

.consulting-area .inner-items .right-info p {
  color: #ffffff;
  padding: 0 10%;
}

.consulting-area .inner-items .right-info a {
  margin-top: 30px;
}

/* ============================================================== 
     # Fun Factor Area
=================================================================== */
.fun-fact-area {
  position: relative;
  z-index: 1;
}

.fun-fact-area::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 120px;
  width: 100%;
  background: #ecf6fa;
  z-index: -1;
}

.fun-fact-area.bg-gray::after {
  background: #ffffff;
}

.dark-layout .fun-fact-area::after {
  background: #101c3d;
}

.fun-fact-area .inner-items {
  position: relative;
  z-index: 1;
  top: -120px;
  margin-bottom: -120px;
  background: linear-gradient(90deg, rgba(70,40,186,1) 32%, rgba(8,113,255,1) 100%);
  padding: 90px 50px;
  border-radius: 8px;
}

.fun-fact-area .inner-items::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(assets/img/map.svg);
  background-position: bottom;
  z-index: -1;
  opacity: 0.4;
  background-size: cover;
}

.fun-fact-area h2 {
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: -8px;
}

.fun-fact-area ul li {
  padding-left: 30px;
  display: block;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.fun-fact-area ul li::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "\4e";
  border-radius: 50%;
  transform: translateY(-50%);
  font-family: 'ElegantIcons';
  font-size: 20px;
}

.fun-fact-area ul li:last-child {
  margin-bottom: 0;
}

.fun-fact-area .timer {
  font-size: 36px;
  display: inline-block;
  font-weight: 900;
  position: relative;
  z-index: 1;
}

.fun-fact-area .medium {
  display: block;
  margin-top: 10px;
}

.fun-fact-area .timer::after {
  position: absolute;
  right: -19px;
  top: -10px;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
}



/* ============================================================== 
     # Services
=================================================================== */
.services-area.with-thumb .services-items .item {
  background: #ffffff;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

.services-area.with-thumb .services-items .item .info {
  padding: 35px;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
  padding-top: 50px;
}

.services-area.with-thumb .services-items .item .thumb {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #febc35;
}

.yellow .services-area.with-thumb .services-items .item .thumb {
  border-bottom: 3px solid #febc35;
}

.services-area.with-thumb .services-items .item .info > i {
  position: absolute;
  left: 40px;
  top: -80px;
  height: 100px;
  width: 90px;
  text-align: center;
  color: #ffffff;
  font-size: 42px;
  z-index: -1;
  line-height: 100px;
  background: #febc35;
}

.yellow .services-area.with-thumb .services-items .item .info > i {
  background: #febc35;
  color: #000000;
}

.services-area.with-thumb .services-items .item .info h4 {
  text-transform: capitalize;
  font-weight: 600;
}

.services-area.with-thumb .services-items .item .info p {
  margin: 0;
}

.services-area.with-thumb .services-items .item .info a:hover {
  color: #febc35;
}

.yellow .services-area.with-thumb .services-items .item .info a:hover {
  color: #febc35;
}

.services-area.with-thumb .services-items .item .info .button {
  border-top: 1px solid #e7e7e7;
  margin-top: 25px;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.services-area.with-thumb .services-items .item .info .button span {
  opacity: 0.1;
  font-size: 36px;
  font-weight: 800;
}

.services-area.with-thumb .services-items .item .info .button a {
  text-transform: uppercase;
  color: #666666;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  z-index: 1;
}

.services-area.with-thumb .services-items .item .info .button a > i {
  font-size: 10px;
  position: relative;
  top: -1px;
}

.services-area.with-thumb .services-items .item:hover .info .button a {
  color: #febc35;
}

.yellow .services-area.with-thumb .services-items .item:hover .info .button a {
  color: #232323;
}

.services-area.with-thumb .services-items .item .info .button a::after {
  position: absolute;
  left: 0;
  bottom: -5px;
  content: "";
  height: 1px;
  width: 40px;
  border-bottom: 1px dashed;
  transition: all 0.35s ease-in-out;
}

.services-area.with-thumb .services-items .item:hover .info .button a::after {
  width: 100%;
}

.services-area .services-items.services-carousel .owl-dots {
  margin-top: 40px;
  margin-bottom: -8px;
}

.services-area .services-items.services-carousel .owl-dots .owl-dot span {
  display: block;
  height: 25px;
  width: 25px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: transparent;
  position: relative;
  z-index: 1;
  opacity: 0.5;
  border: 4px solid #febc35;
}

.yellow .services-area .services-items.services-carousel .owl-dots .owl-dot span {
  border: 4px solid #febc35;
}

.services-area .services-items.services-carousel .owl-dots .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #febc35;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.yellow .services-area .services-items.services-carousel .owl-dots .owl-dot.active span::after {
  background: #febc35;
}

.services-area .services-items.services-carousel .owl-dots .owl-dot.active span {
  opacity: 1;
}

.services-area.icon-less .thumb .overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
  z-index: 1;
}


/* ============================================================== 
     # Services Version Three 
=================================================================== */

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .services-area.icon-only .services-box .single-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.services-area .services-box {
  position: relative;
  z-index: 1;
}

.services-area .fixed-shape {
  position: absolute;
  right: -13%;
  top: -250px;
  width: 400px;
  z-index: -1;
}

.services-area .fixed-shape-left {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
}

.services-area.icon-only .services-box .single-item {
  margin-bottom: 30px;
}

.services-area.icon-only .services-box .item {
  padding: 60px 37px;
  background: #ffffff;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 25px rgb(0 0 0 / 8%);
}

.services-area.icon-only .services-box .item .read-more {
  position: absolute;
  bottom: -22px;
  display: inline-block;
  height: 48px;
  width: 48px;
  background: #febc35;
  line-height: 48px;
  color: #ffffff;
  left: 60%;
  border-radius: 50%;
  text-align: center;
  opacity: 0;
}

.yellow .services-area.icon-only .services-box .item .read-more {
  background: #febc35;
  color: #ffffff;
}

.services-area.icon-only .services-box .item .read-more i {
  font-weight: 500;
}

.services-area.icon-only .services-box .item:hover .read-more,
.services-area.icon-only .services-box .single-item:nth-child(2) .item .read-more {
  left: 50%;
  opacity: 1;
  margin-left: -24px;
}

.services-area.icon-only .services-box .item::before {
  position: absolute;
  left: 0;
  bottom: -1px;
  content: "";
  height: 3px;
  width: 0;
  background: #febc35;
  transition: all 0.35s ease-in-out;
  border-radius: 50%;
}

.yellow .services-area.icon-only .services-box .item::before {
  background: #febc35;
}

.services-area.icon-only .services-box .item:hover::before,
.services-area.icon-only .services-box .single-item:nth-child(2) .item::before {
  width: 100%;
}

.services-area.icon-only .services-box .item > i {
  display: inline-block;
  font-size: 60px;
  margin-bottom: 30px;
  margin-top: 25px;
  transition: all 0.35s ease-in-out;
  color: #febc35;
  position: relative;
}

.yellow .services-area.icon-only .services-box .item > i {
  color: #febc35;
}

.services-area.icon-only .services-box .item > i::after {
  position: absolute;
  right: -40px;
  top: -25px;
  content: "";
  height: 140px;
  width: 140px;
  background: url(assets/img/shape/9.png);
  border-radius: 50%;
  z-index: -1;
  transition: all 0.35s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.1;
}

.services-area.icon-only .services-box .item a {
  margin-top: 10px;
}

.services-area.icon-only .services-box .item h4 a {
  font-weight: 700;
}

.services-area.icon-only .services-box .item p {
  margin-bottom: 0;
}

/* ============================================================== 
     # Thumb Services
=================================================================== */

.thumb-services-area .shape-bottom {
  position: absolute;
  left: -100px;
  bottom: 0;
  height: 105%;
  width: 100%;
  background-size: contain;
  z-index: -1;
  background-position: left bottom;
  background-repeat: no-repeat;
  opacity: 0.1;
}

.thumb-services-area .thumb-services-items .single-item {
  margin-bottom: 30px;
}

.thumb-services-area .thumb-services-items .single-item .item {
  background-size: cover;
  display: block;
  padding-top: 200px;
  background-position: center;
}

.thumb-services-area .thumb-services-items .single-item .content {
  padding: 37px;
  position: relative;
  z-index: 1;
  padding-top: 60px;
}

.thumb-services-area .thumb-services-items .single-item .content::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 90%) repeat scroll 0 0;
  z-index: -1;
  transition: all 0.35s ease-in-out;
}

.thumb-services-area .thumb-services-items .single-item .item:hover .content::after,
.thumb-services-area .thumb-services-items .single-item:first-child .item .content::after {
  height: 130%;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 90%) repeat scroll 0 0;
}

.thumb-services-area .thumb-services-items .single-item .inner > i {
  display: inline-block;
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 25px;
}

.thumb-services-area .thumb-services-items .single-item h4 {
  font-weight: 600;
}

.thumb-services-area .thumb-services-items .single-item p {
  margin-bottom: 0;
}

.thumb-services-area .thumb-services-items .single-item .content a {
  opacity: 0;
  position: absolute;
  bottom: 0;
}

.thumb-services-area .thumb-services-items .single-item .content a i {
  font-weight: 500;
}

.thumb-services-area .thumb-services-items .single-item .content {
  position: relative;
}

.thumb-services-area .thumb-services-items .single-item .item .inner {
  transition: all 0.35s ease-in-out;
}

.thumb-services-area .thumb-services-items .single-item .item:hover .inner,
.thumb-services-area .thumb-services-items .single-item:first-child .item .inner {
  transform: translateY(-70px);
}

.thumb-services-area .thumb-services-items .single-item .item:hover a,
.thumb-services-area .thumb-services-items .single-item:first-child .item a {
  opacity: 1;
  bottom: 37px;
}


/* ============================================================== 
     # Services Details Area
=================================================================== */

.services-details-items h2,
.services-details-items h3,
.services-details-items h4,
.services-details-items h5,
.services-details-items h6 {
  font-weight: 600;
}

.services-details-items .services-sidebar .single-widget {
  margin-top: 50px;
}

.services-details-items .services-sidebar .single-widget:first-child {
  margin-top: 0;
}

.services-details-items .services-sidebar .widget-title {
  font-weight: 800;
  margin-top: -5px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  font-size: 20px;
  display: inline-block;
  padding-bottom: 15px;
  font-size: 22px;
}

.services-details-items .services-sidebar .widget-title::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 2px;
  width: 50px;
  border-bottom: 2px solid #febc35;
}

.yellow .services-details-items .services-sidebar .widget-title::after {
  border-bottom: 2px solid #febc35;
}

.services-details-items .services-sidebar .services-list a {
  display: block;
  padding: 22px 25px;
  /* background: #eef1f6; */
  position: relative;
  z-index: 1;
  font-weight: 600;
  overflow: hidden;
  color: #454545;
  border-bottom: 1px solid #dddddd;
}

.services-details-items .services-sidebar .services-list li.current-item a::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 5px;
  background: #febc35;
}

.yellow .services-details-items .services-sidebar .services-list li.current-item a::before {
  background: #febc35;
}

.services-details-items .services-sidebar .services-list a::after {
  position: absolute;
  right: 25px;
  top: 50%;
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  transform: translateY(-50%);
  font-size: 20px;
}

.services-details-items .services-sidebar .services-list li a:hover {
  color: #febc35;
}

.yellow .services-details-items .services-sidebar .services-list li a:hover {
  color: #febc35;
}

.services-details-items .services-sidebar {
  padding-right: 35px;
}

.services-details-items .services-sidebar .single-widget.quick-contact {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
}

.services-details-items .services-sidebar .single-widget.quick-contact::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #232323;
  opacity: 0.7;
}

.services-details-items .services-sidebar .single-widget.quick-contact .content {
  padding: 50px 30px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.services-details-items .services-sidebar .single-widget.quick-contact .content h4 {
  margin-bottom: 40px;
}

.services-details-items .services-sidebar .single-widget.quick-contact .content i {
  display: inline-block;
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 35px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background: #febc35;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  text-align: center;
}

.yellow .services-details-items .services-sidebar .single-widget.quick-contact .content i {
  background: #febc35;
}

.services-details-items .services-sidebar .single-widget.quick-contact .content i::after {
  position: absolute;
  left: -10%;
  top: -10%;
  content: "";
  height: 120%;
  width: 120%;
  background: #febc35;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
}

.yellow .services-details-items .services-sidebar .single-widget.quick-contact .content i::after {
  background: #febc35;
}

.services-details-items .services-sidebar .single-widget.quick-contact .content p {
  color: #FFFFFFD1;
}

.services-details-items .services-sidebar .single-widget.quick-contact .content h2 {
  font-weight: 700;
  color: #ffffff;
  margin: 0;
}

.services-details-items .services-sidebar .single-widget.brochure ul {
  margin-top: 15px;
}

.services-details-items .services-sidebar .single-widget.brochure a {
  display: flex;
  align-items: center;
  margin-top: 15px;
  background: #febc35;
  color: #ffffff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-size: 13px;
}

.yellow .services-details-items .services-sidebar .single-widget.brochure a {
  background: #febc35;
  color: #232323;
}

.services-details-items .services-sidebar .single-widget.brochure a i {
  font-size: 20px;
  font-weight: 600;
  margin-right: 16px;
}

.services-details-items .services-sidebar .single-widget.brochure li:first-child a {
  margin-top: 0;
}

.services-details-items .services-sidebar .single-widget.brochure li:last-child a {
  background:#febc35;
}

.services-details-items .services-sidebar .single-widget.brochure a:hover,
.services-details-items .services-sidebar .single-widget.brochure li:last-child a:hover {
  background: #febc35;
}

.yellow .services-details-items .services-sidebar .single-widget.brochure a:hover,
.yellow .services-details-items .services-sidebar .single-widget.brochure li:last-child a:hover {
  background: #febc35;
}

.services-details-items .services-single-content h2 {
  font-weight: 600;
  margin-top: 30px;
}

.services-details-area .features .col-lg-6 {
  display: flex;
}

.services-details-area .features i {
  display: inline-block;
  font-size: 50px;
  color: #febc35;
  margin-right: 25px;
}

.yellow .services-details-area .features i {
  color: #febc35;
}

.services-details-area .features h4 {
  font-weight: 600;
}

.services-details-area .features p {
  margin: 0;
}

.services-details-area .features {
  margin: 40px 0;
}

.services-details-area .services-single-content ul {
  list-style: disc;
  margin-left: 20px;
}

.services-details-area .services-single-content ul li {
  line-height: 30px;
}



/* ============================================================== 
     # Work Process 
=================================================================== */

.work-process-area .single-item {
  margin-bottom: 30px;
}

.work-process-area .thumb img {
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border: 9px solid #ffffff;
}

.work-process-area .work-pro-items .item i {
  display: inline-block;
  color: #febc35;
  font-size: 60px;
  margin-bottom: 25px;
}

.yellow .work-process-area .work-pro-items .item i {
  color: #febc35;
}

.work-process-area .work-pro-items .item h4 {
  position: relative;
  z-index: 1;
  line-height: 60px;
  font-weight: 700;
}

.work-process-area .work-pro-items .item h4 span {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 90px;
  z-index: -1;
  color: #eef1f6;
  opacity: 0.5;
  font-weight: 900;
}

.work-process-area .work-pro-items .item {
  border: 1px solid #e7e7e7;
  padding: 20px 30px;
  background: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.work-process-area .work-pro-items .item p {
  margin: 0;
}

/* Animation */
.work-process-area .work-pro-items .item::before, 
.work-process-area .work-pro-items .item::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #febc35;
}

.yellow .work-process-area .work-pro-items .item::before, 
.yellow .work-process-area .work-pro-items .item::after {
  background: #febc35;
}

.work-process-area .work-pro-items .item .item-inner::before, 
.work-process-area .work-pro-items .item .item-inner::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #febc35;
}

.yellow .work-process-area .work-pro-items .item .item-inner::before, 
.yellow .work-process-area .work-pro-items .item .item-inner::after {
  background: #febc35;
}

.work-process-area .work-pro-items .item:hover::before, 
.work-process-area .work-pro-items .item:hover::after {
  width: 100%;
}

.work-process-area .work-pro-items .item:hover .item-inner::before, 
.work-process-area .work-pro-items .item:hover .item-inner::after {
  height: 100%;
}
/*----- button 1 -----*/
.work-process-area .work-pro-items .item::before, 
.work-process-area .work-pro-items .item::after {
  transition-delay: 0.2s;
}

.work-process-area .work-pro-items .item .item-inner::before, 
.work-process-area .work-pro-items .item .item-inner::after {
  transition-delay: 0s;
}

.work-process-area .work-pro-items .item::before {
  right: 0;
  top: 0;
}

.work-process-area .work-pro-items .item::after {
  left: 0;
  bottom: 0;
}

.work-process-area .work-pro-items .item .item-inner::before {
  left: 0;
  top: 0;
}

.work-process-area .work-pro-items .item .item-inner::after {
  right: 0;
  bottom: 0;
}

.work-process-area .work-pro-items .item:hover::before, 
.work-process-area .work-pro-items .item:hover::after {
  transition-delay: 0s;
}

.work-process-area .work-pro-items .item:hover .item-inner::before, 
.work-process-area .work-pro-items .item:hover .item-inner::after {
  transition-delay: 0.2s;
}


/* ============================================================== 
     # Work With Us
=================================================================== */
.work-with-us-area .fun-fact {
  padding: 50px 20px;
  box-shadow: 0 0 10px #cccccc;
  border-bottom: 3px solid #febc35;
  background: #ffff;
  border-radius: 10px;
}

.yellow .work-with-us-area .fun-fact {
  border-bottom: 3px solid #febc35;
}

.work-with-us-area .heading-info h5 {
  font-weight: 600;
  color: #666666;
}

.work-with-us-area .heading-info h2 {
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 30px;
}

.work-with-us-area .fun-fact h4 {
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.5;
  margin-top: -7px;
  font-size: 15px;
}

.work-with-us-area .fun-fact .timer {
  line-height: 1;
  font-size: 36px;
  font-weight: 600;
  color: #232323;
}


/* ============================================================== 
     # Why Chose Us 
=================================================================== */

.why-us-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.why-us-area::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 300px;
  background: url(assets/img/shape/3.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: -1;
}

.why-us-area .info .content {
  position: relative;
  z-index: 1;
}

.why-us-area .info .content > a {
  margin-top: 40px;
}

.why-us-area .info .content::after {
  position: absolute;
  left: 0;
  top: -70px;
  content: "";
  height: 150px;
  width: 150px;
  background: #000000;
  z-index: -1;
  opacity: 0.03;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(45deg);
}

.why-us-area .fixed-thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
}

.why-us-area .thumb {
  position: relative;
  z-index: 1;
  text-align: center;
}

.why-us-area .thumb img {
  max-width: 90%;
}

.why-us-area .thumb::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: #febc35 none repeat scroll 0 0;
  z-index: -1;
  clip-path: polygon(100% 1%, 4% 100%, 100% 100%);
}

.yellow .why-us-area .thumb::after {
  background: #febc35 none repeat scroll 0 0;
}

.why-us-area .info {
  z-index: 1;
  padding-left: 35px;
}

.why-us-area .info ul > a {
  margin-top: 5px;
}

.why-us-area .info .content > h5 {
  font-weight: 600;
  color: #febc35;
  margin-bottom: 30px;
  font-size: 18px;
  margin-top: -5px;
  text-transform: uppercase;
}

.yellow .why-us-area .info .content > h5 {
  color: #febc35;
}

.why-us-area .info h2 {
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  margin-top: -10px;
  margin-bottom: 35px;
}

.why-us-area .info h2 span {
  position: relative;
  z-index: 1;
}

.why-us-area .info li {
  display: block;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
  z-index: 1;
}

.why-us-area .info li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f058";
  color: #febc35;
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 600;
}

.yellow .why-us-area .info li::after {
  color: #febc35;
}

.why-us-area .info li h5 {
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 20px;
}

.why-us-area .info li:last-child {
  margin: 0;
}

.why-us-area .info li p {
  margin: 0;
}

.why-us-area.bg-dark .info li p {
  color: #cccccc;
}

.why-us-area .left-info .content {
  background: #febc35;
  padding: 60px 30px;
}

.yellow .why-us-area .left-info .content {
  background: #febc35;
}

.why-us-area .left-info .content h4 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.yellow .why-us-area .left-info .content h4 {
  color: #000000;
}

.why-us-area .left-info .content h2 {
  font-weight: 600;
}

.yellow .why-us-area .left-info .content h2 {
  color: #000000;
}

.why-us-area .left-info .content a {
  margin-top: 30px;
}

/* ============================================================== 
     # Why Chose Su Version Two
=================================================================== */
.chooseus-area.half-bg {
  position: relative;
  z-index: 1;
}

.chooseus-area.half-bg::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 60px;
  width: 100%;
  background: #ffffff;
  z-index: -1;
}

.chooseus-area .info .content {
  padding-bottom: 15px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .chooseus-area.half-bg {
    padding: 120px 0;
  }

  .chooseus-area .info .content {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .chooseus-area.half-bg::after {
    display: none;
  }
}

.chooseus-area .thumb {
  padding-right: 35px;
}

.chooseus-area .thumb-inner {
  position: relative;
  z-index: 1;
}

.chooseus-area .thumb-inner::after {
  position: absolute;
  left: -25%;
  bottom: -200px;
  content: "";
  height: 400px;
  width: 400px;
  border: 60px solid #febc35;
  z-index: -1;
  border-radius: 50%;
}

.yellow .chooseus-area .thumb-inner::after {
  border: 60px solid #febc35;
}

.chooseus-area .info .content h2 {
  font-weight: 700;
  margin-bottom: 40px;
}

.chooseus-area .info li {
  display: block;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
  z-index: 1;
}

.chooseus-area .info li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f058";
  color: #febc35;
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 600;
}

.yellow .chooseus-area .info li::after {
  color:#febc35;
}

.chooseus-area .info li h5 {
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 20px;
}

.chooseus-area .info li:last-child {
  margin: 0;
}

.chooseus-area .info li p {
  margin: 0;
}

.chooseus-area.bg-dark .info li p {
  color: #cccccc;
}

/* ============================================================== 
     # Team Area 
=================================================================== */

.team-area .fixed-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.team-area .fixed-shape img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.team-area .team-items .single-item {
  margin-bottom: 30px;
}

.team-area .team-items .thumb {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.team-area .team-items .thumb .info {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
  z-index: 1;
  transition: all .65s ease-in-out;
}

.team-area .team-items .item:hover .thumb .info {
  opacity: 0;
  bottom: -100%;
}

.team-area .team-items .thumb::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 50%;
  width: 100%;
  transition: all 0.35s ease-in-out;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 90%) repeat scroll 0 0;
}

.team-area .team-items .item:hover .thumb::after {
  height: 100%;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 90%) repeat scroll 0 0;
}

.team-area .team-items .thumb .info h4 {
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 5px;
}

.team-area .team-items .thumb .info span {
  color: #ffffff;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
}

.team-area .team-items .item > .social {
  background: #ffffff;
  position: relative;
  width: 90%;
  left: 5%;
  top: -25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-bottom: -25px;
}

.team-area .team-items .item > .social li {
  display: inline-block;
  margin: 10px;
}

.team-area .team-items .thumb .overlay {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -10%);
  padding: 30px;
  transition: all 0.35s ease-in-out;
  z-index: 1;
  opacity: 0;
}

.team-area .team-items .item:hover .thumb .overlay {
  opacity: 1;
  transform: translate(0, -50%);
}

.team-area .team-items .thumb .overlay h4 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 10px;
}

.team-area .team-items .thumb .overlay p {
  margin: 0;
}

.team-area .team-carousel .owl-nav {
  margin: 0;
}

.team-area .team-carousel {
  margin-bottom: 15px;
}

.team-area .team-carousel .owl-nav .owl-prev, 
.team-area .team-carousel .owl-nav .owl-next {
  background: transparent none repeat scroll 0 0;
  color: #febc35;
  font-size: 30px;
  height: 40px;
  left: -50px;
  line-height: 40px;
  margin: -20px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 40px;
}

.yellow .team-area .team-carousel .owl-nav .owl-prev, 
.yellow .team-area .team-carousel .owl-nav .owl-next {
  color: #febc35;
}

.team-area .team-carousel .owl-nav .owl-next {
  left: auto;
  right: -50px;
}

.team-area .team-items .thumb .overlay ul li {
  display: inline-block;
  margin: 0 5px;
}

.team-area .team-items .thumb .overlay ul li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background: #ffffff;
  color: #232323;
  border-radius: 50%;
}

.team-area .team-items .thumb .overlay ul {
  margin-top: 20px;
}

.team-area .team-carousel.owl-carousel .owl-dots {
  margin-top: 30px;
  margin-bottom: -8px;
}

.team-area .team-carousel.owl-carousel .owl-dots .owl-dot span {
  display: block;
  height: 25px;
  width: 25px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: transparent;
  position: relative;
  z-index: 1;
  opacity: 0.5;
  border: 4px solid #febc35;
}

.yellow .team-area .team-carousel.owl-carousel .owl-dots .owl-dot span {
  border: 4px solid #febc35;
}

.team-area .team-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #febc35;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.yellow .team-area .team-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  background: #febc35;
}

.team-area .team-carousel.owl-carousel .owl-dots .owl-dot.active span {
  opacity: 1;
}

/* ============================================================== 
     # Team Single  
=================================================================== */

.team-single-area .thumb {
  position: relative;
  z-index: 1;
  margin-right: 30px;
  margin-bottom: 30px;
}

.team-single-area .thumb::after {
  position: absolute;
  right: -30px;
  top: 30px;
  content: "";
  height: 100%;
  width: 100%;
  background: #febc35;
  z-index: -1;
  opacity: 0.2;
}

.yellow .team-single-area .thumb::after {
  background: #febc35;
}

.team-single-area .team-content-top .right-info {
  padding-left: 35px;
}

.team-single-area .team-content-top .right-info h2 {
  font-weight: 600;
}

.team-single-area .team-content-top .right-info span {
  display: block;
  text-transform: uppercase;
  color: #febc35;
  font-weight: 600;
  margin-bottom: 25px;
}

.yellow .team-single-area .team-content-top .right-info span {
  color: #febc35;
}

.team-single-area .right-info ul {
  margin-top: 25px;
  border-top: 1px solid #e7e7e7;
  padding-top: 17px;
}

.team-single-area .right-info ul li {
  margin-top: 10px;
  color: #232323;
}

.team-single-area .right-info ul li a {
  font-weight: 400;
}

.team-single-area .right-info ul li a:hover {
  color: #febc35;
}

.yellow .team-single-area .right-info ul li a:hover {
  color: #febc35;
}

.team-single-area .right-info .social {
  display: flex;
  margin-top: 25px;
  font-weight: 600;
  align-items: center;
}

.team-single-area .right-info .social h4 {
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 25px;
}

.team-single-area .right-info .social ul {
  margin: 0;
  padding: 0;
  border: none;
}

.team-single-area .right-info .social .share-link {
  position: relative;
  z-index: 1;
  margin-left: 15px;
}

.team-single-area .right-info .social .share-link > i {
  display: inline-block;
  height: 45px;
  background: #ffffff;
  box-shadow: 0 0 10px #cccccc;
  line-height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: #febc35;
  margin-right: 20px;
}

.yellow .team-single-area .right-info .social .share-link > i {
  color: #febc35;
}

.team-single-area .right-info .social ul {
  display: flex;
  list-style-type: none;
  grid-gap: 10px;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out 0s;
}

.team-single-area .right-info .social .share-link:hover ul {
  left: 58px;
  opacity: 1;
  pointer-events: auto;
}

.team-single-area .right-info .social ul li {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
}

.team-single-area .right-info .social ul li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 47px;
  background: #f4f5f8;
  text-align: center;
  border-radius: 50%;
}

.team-single-area .bottom-info {
  margin-top: 120px;
}

.team-single-area .bottom-info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.team-single-area .bottom-info p:last-child {
  margin-bottom: 0;
}

.team-single-area .bottom-info .skill-items {
  padding-left: 35px;
}

.skill-items .progress-box {
  margin-bottom: 40px;
}

.skill-items .progress-box:last-child {
  margin-bottom: 0;
}

.skill-items .progress-box h5 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left;
  position: relative;
  z-index: 1;
}

.skill-items .progress-box h5 span {
  position: absolute;
  font-size: 50px;
  line-height: 1;
  top: -21px;
  left: 0;
  z-index: -1;
  opacity: 0.05;
  font-weight: 900;
}

.skill-items .skill-items {
  margin-top: 40px;
}

.skill-items .progress-box .progress {
  background: transparent;
  border-bottom: 2px solid #e7e7e7;
  box-shadow: inherit;
  border-radius: inherit;
  overflow: inherit;
}

.skill-items .progress-box .progress .progress-bar {
  height: 6px;
  border-radius: 30px;
  background: #febc35;
  top: 12px;
  position: relative;
  overflow: inherit;
}

.yellow .skill-items .progress-box .progress .progress-bar {
  background: #febc35;
}

.skill-items .progress-box .progress .progress-bar span {
  position: absolute;
  right: 0;
  top: -50px;
  display: block;
  font-size: 17px;
  color: #232323;
  font-weight: 600;
}

.skill-items .progress-box .progress .progress-bar {
  position: relative;
  z-index: 1;
}

.skill-items .progress-box .progress .progress-bar::after {
  position: absolute;
  right: 13px;
  top: -28px;
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #febc35;
  font-size: 20px;
}

.yellow .skill-items .progress-box .progress .progress-bar::after {
  color: #febc35;
}


/* ============================================================== 
     # Projects 
=================================================================== */

.projects-area .info ul {
  background: #febc35;
  margin-top: 25px;
  color: #ffffff;
  padding: 30px;
  padding-top: 0;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.yellow .projects-area .info ul {
  background: #febc35;
  color: #000000;
}

.projects-area .info ul::after {
  position: absolute;
  left: 30px;
  top: -10px;
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #febc35;
}

.yellow .projects-area .info ul::after {
  border-bottom: 10px solid #febc35;
}

.projects-area .info ul li {
  float: left;
  width: auto;
  margin-right: 50px;
  margin-top: 35px;
  text-transform: capitalize;
}

.projects-area .info ul li h5 {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  color: #ffffff;
}

.yellow .projects-area .info ul li h5 {
  color: #000000;
}

.projects-area .info ul li span {
  text-transform: uppercase;
  font-size: 14px;
}

.projects-area .project-item .thumb {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.projects-area .project-item .thumb::after {
  position: absolute;
  left: 0;
  bottom: -50%;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) repeat scroll 0 0;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}

.projects-area .project-item .thumb:hover::after {
  bottom: 0;
  opacity: 0.7;
}

.projects-area .project-item .thumb a {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #ffffff;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -90%);
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 10px #cccccc;
  opacity: 0;
  color: #febc35;
}

.yellow .projects-area .project-item .thumb a {
  color: #febc35;
}

.projects-area .project-item.projects-carousel .owl-nav i {
    font-weight: 400;
    font-size: 35px;
}

.projects-area .project-item .thumb:hover a {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.projects-area .item .info h4 {
  font-weight: 600;
  text-transform: uppercase;
  color: #febc35;
  display: inline-block;
  position: relative;
  z-index: 1;
  font-size: 16px;
}

.yellow .projects-area .item .info h4 {
  color: #666666;
}

.projects-area .item .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.projects-area .project-item.projects-carousel .owl-nav {
  margin: 0;
}

.projects-area .project-item.projects-carousel .owl-nav .owl-prev, 
.projects-area .project-item.projects-carousel .owl-nav .owl-next {
  background: transparent none repeat scroll 0 0;
  color: #febc35;
  font-size: 30px;
  height: 40px;
  left: -70px;
  line-height: 40px;
  margin: -20px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 40px;
}

.yellow .projects-area .project-item.projects-carousel .owl-nav .owl-prev, 
.yellow .projects-area .project-item.projects-carousel .owl-nav .owl-next {
  color: #febc35;
}

.projects-area .project-item.projects-carousel .owl-nav .owl-next {
  left: auto;
  right: -70px;
}

/* ============================================================== 
     # Project Details
=================================================================== */


.project-details-area .content h2,
.project-details-area .content h3,
.project-details-area .content h4,
.project-details-area .content h5,
.project-details-area .content h6 {
  font-weight: 600;
}

.project-details-area .content h2,
.project-details-area .content h3 {
  margin-bottom: 20px;
  margin-top: -8px;
}

.project-details-area .content h4,
.project-details-area .content h5,
.project-details-area .content h6 {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.project-details-area .content h3 {
  margin-top: 30px;
}

.project-details-area .content .thumb {
  margin-bottom: 15px;
  margin-top: 15px;
}

.project-details-area .content ul {
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: -10px;
}

.project-details-area .content ul li {
  position: relative;
  z-index: 1;
  float: left;
  width: 50%;
  padding: 0 15px;
  padding-left: 25px;
  position: relative;
  z-index: 1;
  font-weight: 600;
  margin-top: 10px;
}

.project-details-area .content ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #febc35;
}

.yellow .project-details-area .content ul li::after {
  color: #febc35;
}

.project-details-area .content blockquote {
  position: relative;
  z-index: 1;
  border: none;
  padding-left: 60px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #fafafa;
  padding: 30px;
  color: #232323;
  font-weight: 300;
  border-left: 4px solid;
}

.project-details-area .content blockquote::after {
  position: absolute;
  right: 30px;
  content: "\f10e";
  font-family: "Font Awesome 5 Pro";
  color: #dddddd;
  font-size: 60px;
  bottom: 30px;
  font-weight: 600;
}

.project-details-area .content blockquote .footer {
  display: block;
  margin-top: 17px;
}

.project-details-area .content blockquote .footer p {
  margin: 0;
  position: relative;
  display: inline-block;
  color: #232323;
  font-weight: 600;
  font-style: italic;
}

.project-details-area .content p:last-child {
  margin-bottom: 0;
}

.project-details-area .content .row {
  margin-top: 50px;
}

.project-details-area .content .row img {
  margin-bottom: 0;
}

/* ============================================================== 
     # Gallery 
=================================================================== */

.gallery-area .magnific-mix-gallery {
  margin: -15px;
}

.gallery-area .gallery-items.colums-3 .pf-item {
  float: left;
  padding: 15px;
  width: 33.3333%;
}

.gallery-area .gallery-items.colums-2 .pf-item {
  float: left;
  padding: 15px;
  width: 50%;
}

.gallery-area .gallery-items.colums-4 .pf-item {
  float: left;
  padding: 15px;
  width: 25%;
}

.gallery-area .gallery-items .pf-item .overlay {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.gallery-area .gallery-items .pf-item .overlay:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 170%;
  top: 0;
  transform: skewX(45deg);
  transition: all 0.55s ease-in-out;
  background-color: #febc35;
  opacity: 0.6;
}

.yellow .gallery-area .gallery-items .pf-item .overlay:after {
  background-color: #febc35;
}

.gallery-area .gallery-items .pf-item .overlay:hover:after {
  left: -170%;
  top: 0;
}

.gallery-area .gallery-items .pf-item .overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  opacity: 0;
  transition: all 0.5s ease 0s;
}

.gallery-area .gallery-items .pf-item .overlay:hover:before {
  opacity: 1;
}

.gallery-area .gallery-items .pf-item .overlay .content {
  position: absolute;
  left: 40px;
  bottom: 30px;
  z-index: 1;
}

.gallery-area .gallery-items .pf-item .overlay .content .title {
  transition: all 0.35s ease-in-out;
  transform: translateX(50px);
  opacity: 0;
}

.gallery-area .gallery-items .pf-item .overlay:hover .content .title {
  transform: translateX(0);
  opacity: 1;
}

.gallery-area .gallery-items .pf-item .overlay .content .title span {
  color: #cccccc;
  display: block;
  margin-bottom: 10px;
}

.gallery-area .gallery-items .pf-item .overlay .content .title a {
  color: #ffffff;
}

.gallery-area .gallery-items .pf-item .overlay .content > a {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: #febc35;
  color: #ffffff;
  border-radius: 50%;
  transform: translateY(30px);
  opacity: 0;
}

.yellow .gallery-area .gallery-items .pf-item .overlay .content > a {
  background: #febc35;
}

.gallery-area .gallery-items .pf-item .overlay .content > a i {
  font-weight: 500;
}

.gallery-area .gallery-items .pf-item .overlay:hover .content > a {
  transform: translateY(0);
  opacity: 1;
}


/* ============================================================== 
     # Portfolio 
=================================================================== */

@media only screen and (min-width: 1367px) {
  .portfolio-area .container-full {
    margin-left: calc((100% - 1260px)/ 2);
    width: auto;
    min-width: auto;
    max-width: inherit;
  }
}

.portfolio-area .bottom-sahpe {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: bottom center;
}

.portfolio-area.half-bg-dark {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.portfolio-area.half-bg-dark::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: #022147 none repeat scroll 0 0 !important;
  z-index: -1;
}

.portfolio-area .portfolio-items-area .mix-item-menu button {
  display: inline-block;
  background: transparent;
  border: none;
  text-transform: capitalize;
  margin-bottom: 15px;
  box-shadow: inherit;
  padding: 7px 15px;
  clip-path: polygon(6% 0, 100% 0%, 94% 100%, 0% 100%);
}

.portfolio-area .portfolio-items-area .mix-item-menu {
  margin-bottom: 15px;
}

.portfolio-area .portfolio-items-area .mix-item-menu button.active {
  background: #febc35;
  color: #ffffff;
}

.yellow .portfolio-area .portfolio-items-area .mix-item-menu button.active {
  background: #febc35;
  color: #232323;
}

.portfolio-items-area .gallery-content {
  margin: 0 -15px;
  margin-bottom: -45px;
}

.portfolio-area .gallery-items .pf-item .item {
  margin-bottom: 30px !important;
}

.portfolio-area .gallery-items.colums-3 .pf-item {
  float: left;
  padding: 15px;
  width: 33.3333%;
}

.portfolio-area .gallery-items.colums-2 .pf-item {
  float: left;
  padding: 15px;
  width: 50%;
}

.portfolio-area .gallery-items.colums-4 .pf-item {
  float: left;
  padding: 15px;
  width: 25%;
}

.portfolio-area .item .info {
  padding: 30px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -30px;
  background: #ffffff;
  left: 45px;
  box-shadow: 0px 0px 13.5px 1.5px rgba(0, 0, 0, 0.05);
}

.portfolio-area.half-bg-dark .item .info::after {
  position: absolute;
  left: 0;
  bottom: 9px;
  content: "";
  height: 1px;
  width: 100%;
  background: #ffffff;
  z-index: -1;
  opacity: 0.3;
}

.portfolio-area .item .info h5 {
  /* margin: 0; */
}

.portfolio-area .item .info span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  display: block;
  margin-bottom: 10px;
}

.portfolio-area .item .info span i {
  margin-right: 5px;
  color: #febc35;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-weight: 500;
}

.yellow .portfolio-area .item .info span i {
  color: #febc35;
}

.portfolio-area .item .info a {
  margin-top: 20px;
}

.portfolio-area .item .info a:hover {
  color: #febc35 !important;
}

.yellow .portfolio-area .item .info a:hover {
  color: #febc35 !important;
}

.portfolio-area .item .info > a {
  background: #e9e9e9;
  display: inline-block;
  height: 100%;
  padding: 12px 20px;
  margin: 0 !important;
  font-size: 15px;
}

.portfolio-area.half-bg-dark .item .info > a {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.portfolio-area .item .info > a:hover {
  background: #febc35 !important;
  color: #ffffff !important;
}

.yellow .portfolio-area .item .info > a:hover {
  background: #febc35 !important;
  color: #232323 !important;
}

.portfolio-area.half-bg-dark .item .info a {
  color: #ffffff;
  background: #022147;
  padding-right: 20px;
}

.portfolio-area.half-bg-dark .item .info span {
  color: #cccccc;
}

.portfolio-area .item .thumb {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.portfolio-area .item .thumb .overlay a {
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: #febc35;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 0 10px #cccccc;
  color: #ffffff;
  opacity: 0;
  text-align: center;
}

.yellow .portfolio-area .item .thumb .overlay a {
  background: #febc35;
}

.portfolio-area .item .thumb:hover .overlay a {
  opacity: 1;
}

.portfolio-area .portfolio-carousel .owl-nav {
  margin: 0;
}

.portfolio-area .portfolio-carousel .owl-nav .owl-prev, 
.portfolio-area .portfolio-carousel .owl-nav .owl-next {
  background: transparent none repeat scroll 0 0;
  color: #ffffff;
  font-size: 30px;
  height: 40px;
  left: -50px;
  line-height: 40px;
  margin: -20px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 40px;
  opacity: 0;
  transition: all 0.35s ease-in-out;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
}

.portfolio-area .portfolio-carousel .owl-nav .owl-next {
  left: auto;
  right: -50px;
}

.portfolio-area .portfolio-carousel:hover .owl-nav .owl-prev {
  left: 50px;
  opacity: 1;
}

.portfolio-area .portfolio-carousel:hover .owl-nav .owl-next {
  right: 50px;
  opacity: 1;
}

@media only screen and (min-width: 1460px) {
  .left-border::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 80px;
    background: #ffffff;
    border-radius: 0 30px 30px 0;
  }
}

.portfolio-area .item {
  position: relative;
  z-index: 1;
  padding-right: 30px;
}

.portfolio-carousel .owl-stage-outer {
  padding-bottom: 50px;
  margin-bottom: -20px;
}

.portfolio-area .item .info .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  margin-top: 20px;
  padding-top: 20px;
}

.portfolio-area .item .info .bottom a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background: #febc35;
  text-align: center;
  color: #ffffff;
  border-radius: 6px;
  margin: 0;
  margin-right: 30px;
}

.yellow .portfolio-area .item .info .bottom a {
  background: #febc35;
}

.portfolio-area .item .info .bottom span {
  margin: 0;
  color: #666666;
}

.portfolio-area .item .info .bottom a:hover {
  background: #022147;
  color: #ffffff !important;
}

.portfolio-list a.load-more__btn {
  margin: 0 15px;
  display: inline-block;
  padding: 12px 40px;
  background: #febc35;
  color: #ffffff;
  border-radius: 30px;
  margin-top: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 15px;
  margin-left: 0;
}

.yellow .portfolio-list a.load-more__btn {
  background: #febc35;
}

.magnific-mix-gallery.masonary.portfolio-list .gallery-items {
  overflow: hidden;
}


/* Portfolio Effect */
.portfolio-area .item .thumb img {
  max-width: 100%;
  position: relative;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.portfolio-area .item .thumb:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.portfolio-area .item .thumb:hover img {
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  background-position: top;
  -webkit-filter: grayscale(0.4) blur(2px);
  filter: grayscale(0.4) blur(2px);
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  -webkit-transition-duration: 0.45s;
  transition-duration: 0.45s;
}

.portfolio-area .item .thumb:hover img {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
}

.portfolio-area .item .thumb img {
  display: inline-block;
  -moz-transform: scale(1.09, 1.09);
  -ms-transform: scale(1.09, 1.09);
  -webkit-transform: scale(1.09, 1.09);
  transform: scale(1.09, 1.09);
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.portfolio-area .item .thumb:hover:before,
.portfolio-area .item .thumb.hover:before,
.portfolio-area .item .thumb:hover:after,
.portfolio-area .item .thumb.hover:after {
  opacity: 1;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.portfolio-area  .portfolio-carousel.owl-carousel .owl-dots {
  margin-top: 50px;
  margin-bottom: -8px;
}

.portfolio-area .portfolio-carousel.owl-carousel .owl-dots .owl-dot span {
  display: block;
  height: 25px;
  width: 25px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: transparent;
  position: relative;
  z-index: 1;
  opacity: 0.5;
  border: 4px solid #febc35;
}

.yellow .portfolio-area .portfolio-carousel.owl-carousel .owl-dots .owl-dot span {
  border: 4px solid #febc35;
}

.portfolio-area  .portfolio-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #febc35;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.yellow .portfolio-area  .portfolio-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  background: #febc35;
}

.portfolio-area  .portfolio-carousel.owl-carousel .owl-dots .owl-dot.active span {
  opacity: 1;
}

/* Project Details */
.project-details-area .content .project-info ul li {
  padding-left: 0;
  float: left;
  width: 50%;
  margin: 0;
  margin-top: 10px;
}

.project-details-area .content .project-info ul li::after {
  display: none;
}

.project-details-area .project-info {
  padding: 30px;
  border: 1px solid #e7e7e7;
  background: #eef1f6;
  margin-left: 35px;
  margin-top: 10px;
}

.project-details-area .project-info span {
  display: block;
}

.project-details-area .project-info h5 {
  margin-bottom: 5px;
}

.project-details-area .project-info .item {
  margin-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 15px;
}

.project-details-area .project-info .item:last-child {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.project-details-area .content .project-info ul li span, 
.project-details-area .content .project-info ul li span a {
  color: #777777;
  text-decoration: underline;
}

.project-details-area .content .project-info p:last-child {
  margin-bottom: 0;
}

.project-details-area .default-one-col-carousel .owl-nav {
  margin: 0;
}

.project-details-area .default-one-col-carousel .owl-nav .owl-prev, 
.project-details-area .default-one-col-carousel .owl-nav .owl-next {
  background: transparent none repeat scroll 0 0;
  color: #febc35;
  font-size: 30px;
  height: 40px;
  left: 15px;
  line-height: 40px;
  margin: -20px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 40px;
}

.yellow .project-details-area .default-one-col-carousel .owl-nav .owl-prev, 
.yellow .project-details-area .default-one-col-carousel .owl-nav .owl-next {
  color: #febc35;
}

.project-details-area .default-one-col-carousel .owl-nav .owl-next {
  left: auto;
  right: 15px;
}




/* ============================================================== 
     # Clients Area 
=================================================================== */

.clients-area.default-padding.bg-dark.text-light::after {
  position: absolute;
  left: -15%;
  top: 0;
  height: 101%;
  width: 63%;
  content: "";
  background: #febc35;
  z-index: -1;
  transform: skewX(-10deg);
}

.yellow .clients-area.default-padding.bg-dark.text-light::after {
  background: #febc35;
}

.clients-area .fixed-shape {
  position: absolute;
  left: 0;
  top: 0;
}

.clients-area .fixed-shape img {
  height: 400px;
}

.clients-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.clients-area .clients-box img {
  width: auto;
  margin: auto;
  height: 65px;
}

.clients-area .left-info h2 {
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-top: -8px;
}

.yellow .clients-area .left-info h2 {
  color: #232323;
}

.clients-area .left-info p {
  margin: 0;
  margin-bottom: -5px;
}

.yellow .clients-area .left-info p {
  color: #232323;
}

/* ============================================================== 
     # Testimonials 
=================================================================== */
.testimonials-area .testimonial-content .owl-item {
  position: relative;
  z-index: 1;
}

.testimonials-area .testimonial-content .owl-item::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 400px;
  width: 400px;
  background: url(assets/img/shape/map.png);
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  opacity: 0.09;
}

.testimonial-items h1 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  font-size: 250px;
  font-weight: 900;
  margin: 0;
  line-height: 0.8;
  left: 30px;
  margin-bottom: 20px;
  margin-top: -20px;
  margin-left: -8px;
}

.testimonial-items .title h2 {
  font-weight: 600;
  margin-bottom: 0;
  margin-top: -5px;
}

.testimonial-items .title a {
  margin-top: 10px;
}

.testimonials-area .testimonial-content .provider {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.testimonials-area .testimonial-content .provider img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 25px;
  border: 5px solid #ffffff;
  box-shadow: 0 0 25px rgb(0 0 0 / 25%);
}

.testimonials-area .testimonial-content .content {
  padding-left: 105px;
}

.testimonials-area .testimonial-content .provider .info h5 {
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
}

.testimonials-area .testimonial-content .provider .info span {
  display: block;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.testimonials-area .testimonial-content .content .rating {
  display: block;
  margin-bottom: 30px;
  color: #febc35;
}

.yellow .testimonials-area .testimonial-content .content .rating {
  color: #febc35;
}

.testimonials-area .testimonial-content p {
  font-size: 18px;
  line-height: 34px;
  margin-top: -5px;
  color: #232323;
  font-weight: 400;
}

.testimonials-area .testimonial-content.testimonials-carousel .owl-nav {
  position: absolute;
  bottom: 55px;
  right: 30px;
}

.testimonials-area .testimonial-content.testimonials-carousel .owl-nav i {
  font-weight: 500;
}

.testimonials-area .testimonial-content.testimonials-carousel .owl-nav .owl-prev, 
.testimonials-area .testimonial-content.testimonials-carousel .owl-nav .owl-next {
  background: transparent;
  color: #febc35;
  font-size: 24px;
  margin: 0;
  padding: 10px;
}

.yellow .testimonials-area .testimonial-content.testimonials-carousel .owl-nav .owl-prev, 
.yellow .testimonials-area .testimonial-content.testimonials-carousel .owl-nav .owl-next {
  color: #000000;
}

.testimonials-area .testimonial-content.testimonials-carousel .owl-dots {
  margin-top: 30px;
  margin-bottom: -8px;
  text-align: left;
  margin-left: 25px;
}

.testimonials-area .testimonial-content.testimonials-carousel .owl-dots .owl-dot span {
  display: block;
  height: 25px;
  width: 25px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: transparent;
  position: relative;
  z-index: 1;
  opacity: 0.5;
  border: 4px solid #febc35;
}

.yellow .testimonials-area .testimonial-content.testimonials-carousel .owl-dots .owl-dot span {
  border: 4px solid #febc35;
}

.testimonials-area .testimonial-content.testimonials-carousel .owl-dots .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #febc35;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.yellow .testimonials-area .testimonial-content.testimonials-carousel .owl-dots .owl-dot.active span::after {
  background: #febc35;
}

.testimonials-area .testimonial-content.testimonials-carousel .owl-dots .owl-dot.active span {
  opacity: 1;
}

.testimonials-area .testimonial-content .item {
  padding: 50px;
  position: relative;
  z-index: 1;
  margin-left: 30px;
  border-radius: 8px;
  border: 2px solid #eef1f6;
}

.testimonials-area.bg-gray .testimonial-content .item {
  background: #ffffff;
}

.testimonials-area .testimonial-content .item::after {
  position: absolute;
  left: -15px;
  top: 30px;
  content: "";
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #eef1f6;
  z-index: 1;
}

.testimonials-area.bg-gray .testimonial-content .item::after {
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #ffffff;
}

.testimonials-area .testimonial-content .item::before {
  position: absolute;
  left: 50px;
  top: 50px;
  font-weight: 600;
  font-size: 70px;
  color: #232323;
  content: "";
  background: url(assets/img/shape/quote.png);
  background-size: contain;
  height: 60px;
  width: 55px;
  background-repeat: no-repeat;
  background-position: left top;
}


/* ============================================================== 
     # Quick Support 
=================================================================== */
.quick-support-area {
  padding: 180px 0;
}

.quick-support-area .quick-support-box {
  position: relative;
  z-index: 1;
}

.quick-support-area .fixed-shape {
  position: absolute;
  left: 0;
  bottom: -180px;
  height: 350px;
  width: 100%;
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
  opacity: 0.05;
}

.quick-support-area h2 {
  font-weight: 600;
  margin-bottom: 50px;
  line-height: 1.3;
}


/* ============================================================== 
     # Blog 
=================================================================== */

@media (min-width: 1400px) {
  .blog-area.full-blog .container {
    max-width: 1300px;
    width: 1300px;
  }
}

.blog-area .single-item {
  margin-bottom: 30px;
}

.blog-area.full-blog .single-item {
  margin-bottom: 50px;
}

.blog-area .blog-items .item {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
}

.blog-area.home-blog .blog-items .item {
  box-shadow: inherit;
  background: transparent;
}

.blog-area .thumb {
  position: relative;
  z-index: 1;
}

.blog-area.full-blog .thumb img {
  border-radius: 8px;
}

.blog-area .thumb .date {
  position: absolute;
  right: 30px;
  bottom: -31px;
  background: #ffffff;
  color: #232323;
  font-size: 20px;
  text-align: center;
  display: inline-block;
  line-height: 32px;
}

.blog-area .thumb .date strong {
  display: block;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  background: #febc35;
  color: #ffffff;
  padding: 0 21px;
}

.yellow .blog-area .thumb .date strong {
  background: #febc35;
  color: #000000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-area .thumb .date {
    bottom: 0;
  }
}

.blog-area .item .info {
  padding-top: 30px;
  overflow: hidden;
}

.blog-area.home-blog .item .info {
  padding: 0;
  padding-top: 40px;
  padding-right: 20px;
}

.blog-area.home-blog.date-less .item .info {
  padding-top: 30px;
}

.blog-area.home-blog .item .info p:last-child {
  padding-bottom: 0;
}

.blog-area .info .footer-entry {
  float: left;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.blog-area .info .footer-entry h4 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  margin-right: 20px;
  margin-top: 7px;
}

.blog-area .info .footer-entry a {
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  background: #ffffff;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 20px;
  border-radius: 30px;
  border: 1px solid #e7e7e7;
}

.blog-area .info .footer-entry a:hover {
  background: #febc35;
  color: #ffffff;
  border-color: #febc35;
}

.yellow .blog-area .info .footer-entry a:hover {
  background: #febc35;
  color: #232323;
  border-color: #febc35;
}

.blog-area .item .info .meta {
  margin-bottom: 15px;
}

.blog-area .item .info .meta ul {
  display: flex;
  align-items: center;
}

.blog-area .btn {
  margin-top: 5px;
}

.blog-area.full-blog .btn {
  margin-top: 10px;
}

.blog-area .item .info .meta ul li {
  margin-right: 15px;
  position: relative;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 15px;
  color: #999;
}

.blog-area .item .info .meta ul li i {
  margin-right: 5px;
  font-size: 16px;
  position: relative;
  top: 1px;
  font-weight: 500;
}

.blog-area .item .info .meta ul li a {
  color: #999;
  font-weight: 500;
}

.blog-area .item .info .meta ul li a:hover {
  color: #febc35;
}

.yellow .blog-area .item .info .meta ul li a:hover {
  color: #febc35;
}

.blog-area .item .info h2,
.blog-area .item .info h3,
.blog-area .item .info h4 {
  font-weight: 600;
  line-height: 1.4;
}

.blog-area .item .info h2 a:hover,
.blog-area .item .info h3 a:hover,
.blog-area .item .info h4 a:hover {
  color: #febc35;
}

.yellow .blog-area .item .info h2 a:hover,
.yellow .blog-area .item .info h3 a:hover,
.yellow .blog-area .item .info h4 a:hover {
  color: #febc35;
}

.blog-area.full-blog h3 {
  font-size: 30px;
}

.blog-area .item .info .content h4 a:hover {
  color: #febc35;
}

.yellow .blog-area .item .info .content h4 a:hover {
  color: #febc35;
}

/* Pagination */
.pagi-area .pagination {
  text-align: center;
  display: block;
  margin-top: 0;
}

.pagi-area .pagination li.page-item {
  float: none;
  display: inline-block;
}

.pagi-area .pagination li a {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 0 2px;
  margin-top: 5px;
  color: #232323;
  font-weight: 800;
}

.yellow .pagi-area .pagination li.active a {
  background: #febc35;
  border-color: #febc35;
}

.pagi-area .pagination li.active a {
  background: #febc35;
  border-color: #febc35;
}

/* Sidebar */

.blog-area.left-sidebar .blog-content {
  float: right;
}

.blog-area.left-sidebar .sidebar {
  padding-right: 35px;
}

.blog-area.right-sidebar .sidebar {
  padding-left: 35px;
}

.blog-area .sidebar .title {
  display: block;
}

.blog-area .sidebar .title h4 {
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: -5px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  font-size: 22px;
  display: inline-block;
  padding-bottom: 15px;
}

.blog-area .sidebar .title h4::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 2px;
  width: 50px;
  border-bottom: 2px solid #febc35;
}

.yellow .blog-area .sidebar .title h4::after {
  border-bottom: 2px solid #febc35;
}

.blog-area .sidebar input[type="text"] {
  border: none;
  box-shadow: inherit;
  min-height: 60px;
  background: #f4f5f8;
  border-radius: 8px;
  padding-left: 20px;
}

.blog-area .sidebar form {
  position: relative;
}

.blog-area .sidebar button[type="submit"] {
  border: none;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
  min-height: 50px;
  width: 50px;
  position: absolute;
  right: 5px;
  text-transform: uppercase;
  top: 5px;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  font-size: 16px;
  background: #febc35;
  border-radius: 8px;
}

.yellow .blog-area .sidebar button[type="submit"] {
  color: #232323;
  background: #febc35;
}

.yellow .blog-area .sidebar input[type="submit"]:hover {
  background: #febc35 none repeat scroll 0 0;
}

.blog-area .sidebar input[type="submit"]:hover {
  background: #febc35 none repeat scroll 0 0;
}


.blog-area .sidebar .sidebar-item {
  float: left;
  margin-bottom: 50px;
  width: 100%;
  background: #f4f5f8;
  border-radius: 8px;
  padding: 40px;
}

.blog-area .sidebar .sidebar-item.search {
  background: transparent;
  padding: 0;
}

.blog-area .sidebar .sidebar-item:last-child {
  margin-bottom: 0;
}

.blog-area .sidebar .sidebar-item.category .sidebar-info, 
.blog-area .sidebar .sidebar-item.archives .sidebar-info {
  margin-top: -5px;
}

.blog-area .sidebar .sidebar-item.category li {
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.blog-area .sidebar .sidebar-item.category li a {
  display: flex;
  text-transform: capitalize;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 13px;
  color: #454545;
}

.blog-area .sidebar-item.category li a::after {
  position: absolute;
  left: 0;
  top: 6px;
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  font-size: 12px;
}

.blog-area .sidebar .sidebar-item li a:hover {
  color: #febc35;
}

.yellow .blog-area .sidebar .sidebar-item li a:hover {
  color: #febc35;
}

.blog-area .sidebar .sidebar-item.category li a span {
  font-size: 12px;
  margin-left: 5px;
  background: #ffffff;
  min-width: 40px;
  padding: 2px 5px;
  text-align: center;
  border-radius: 30px;
  margin: 3px 0;
  font-weight: 600;
}

.blog-area .sidebar .sidebar-item.category li:first-child a span {
  top: 0;
}

.blog-area .sidebar .sidebar-item.category li:first-child {
  margin-top: 0;
  padding-top: 0;
}

.blog-area .sidebar .sidebar-item.category li:last-child {
  border: medium none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sidebar-item.recent-post li a {
  color: #232323;
  display: block;
  font-weight: 700;
}

.sidebar-item.recent-post li a:last-child {
  display: inline-block;
}

.sidebar-item.recent-post .meta-title {
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 13px;
}

.sidebar-item.recent-post .meta-title i {
  margin-right: 3px;
  font-weight: 500;
}

.sidebar-item.recent-post li a:hover {
  color: #febc35;
}

.yellow .sidebar-item.recent-post li a:hover {
  color: #febc35;
}

.sidebar-item.recent-post li a span {
  display: inline-block;
  color: #002359;
}

.sidebar-item.recent-post li span {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.sidebar-item.recent-post .meta-title a {
  color: #999999;
}

.sidebar-item.recent-post li {
  color: #cdd0d3;
}

.sidebar-item.recent-post li {
  margin-bottom: 25px;
}

.sidebar-item.recent-post li:last-child {
  border: medium none;
  margin: 0;
  padding: 0;
}

.sidebar-item.recent-post li:last-child {
  margin: 0;
}

.sidebar-item.recent-post li .thumb {
  display: table-cell;
  padding-top: 5px;
  vertical-align: top;
  width: 80px;
}

.sidebar-item.recent-post .thumb img {
  width: 100%;
  border-radius: inherit;
}

.sidebar-item.recent-post li .info {
  box-shadow: inherit;
  color: #837f7e;
  display: table-cell;
  line-height: 26px;
  padding: 0;
  padding-left: 25px;
  vertical-align: top;
}

.sidebar-item.recent-post li .info a {
  text-decoration: inherit;
  font-weight: 600;
  font-size: 17px;
  margin-top: 0;
  color: #454545;
  line-height: 23px;
}

.blog-area .sidebar-item.gallery ul {
  margin: -7px;
  overflow: hidden;
}

.sidebar-item.gallery ul li {
  box-sizing: border-box;
  float: left;
  padding: 7px;
  width: 33.333%;
}

.sidebar-item.gallery ul li img {
  min-height: 70px;
  width: 100%;
}

.sidebar-item.archives ul li {
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.sidebar-item.archives ul li:last-child {
  margin-bottom: 0;
}

.sidebar-item.archives ul li a {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  z-index: 1;
  padding-left: 25px;
  font-size: 14px;
  color: #454545;
}

.sidebar-item.archives ul li a::after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "\f07b";
  font-family: "Font Awesome 5 Pro";
  height: 8px;
  left: 0;
  position: absolute;
  width: 8px;
  font-weight: 500;
}

.sidebar-item.social-sidebar ul {
  margin-bottom: -10px;
  overflow: hidden;
}

.sidebar-item.social-sidebar li {
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.sidebar-item.social-sidebar li a {
  background: #002359 none repeat scroll 0 0;
  border: medium none;
  color: #ffffff;
  display: inline-block;
  height: 50px;
  line-height: 54px;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
  font-size: 14px;
  border-radius: 50%;
}

.sidebar-item.social-sidebar li a:hover {
  color: #ffffff !important;
}

.sidebar-item.social-sidebar li.facebook a {
  background: #3B5998 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.twitter a {
  background: #1DA1F2 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.pinterest a {
  background: #BD081C none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.g-plus a {
  background: #DB4437 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.linkedin a {
  background: #0077B5 none repeat scroll 0 0;
}

.sidebar-item.tags ul {
  margin-top: -8px;
}

.sidebar-item.tags ul li {
  display: inline-block;
}

.sidebar-item.tags ul li a {
  border: 1px solid #e7e7e7;
  display: inline-block;
  font-weight: 500;
  margin-top: 8px;
  margin-right: 5px;
  padding: 5px 13px;
  text-transform: capitalize;
  font-size: 13px;
  border-radius: 30px;
  background: #ffffff;
  color: #454545;
}

.sidebar-item.tags ul li a:hover {
  color: #febc35;
}

.yellow .sidebar-item.tags ul li a:hover {
  color: #febc35;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 50px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #febc35;
  z-index: -1;
  opacity: 0.7;
}

.yellow .blog-area .sidebar .sidebar-item.add-banner .sidebar-info::after {
  background: #febc35;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info h4 {
  color: #ffffff;
  font-weight: 700;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info p {
  color: #ffffff;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info a {
  display: inline-block;
  color: #ffffff;
  border: 1px dashed rgba(255, 255, 255, 0.7);
  padding: 10px 25px;
  margin-top: 9px;
}

.blog-area .blog-items.content-less .item .info > a {
  margin-top: 10px;
}

/* Blog Single */
.blog-area.single .item .content-box span {
  background: #febc35 none repeat scroll 0 0;
  color: #ffffff;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 3px 20px;
  text-transform: uppercase;
}

.yellow .blog-area.single .item .content-box span {
  background: #febc35 none repeat scroll 0 0;
}

.blog-area.single .item .content-box h2,
.blog-area.single .item .content-box h3,
.blog-area.single .item .content-box h4 {
  font-weight: 500;
}

.blog-area.single .content-box .cats {
  float: left;
  margin-right: 5px;
}

.blog-area.single .content-box .meta .date {
  float: right;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blog-area.single .content-box .meta {
  overflow: hidden;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
}

.blog-area .blog-items .info > ul li {
  margin-bottom: 5px;
  color: #232323;
  position: relative;
  z-index: 1;
  padding-left: 15px;
  display: block;
}

.blog-area .blog-items .info > ul {
  margin-bottom: 20px;
  margin-top: 20px;
}

.blog-area .blog-items .info > ul li::after {
  position: absolute;
  left: 0;
  top: 10px;
  content: "";
  height: 5px;
  width: 5px;
  background: #333333;
  border-radius: 50%;
}

.blog-area.single .item {
  margin-bottom: 0;
}

.blog-area.full-blog .blog-items .item {
  box-shadow: inherit;
}

.blog-area .item blockquote {
  position: relative;
  z-index: 1;
  border: none;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #ffffff;
  line-height: 36px;
  background: #febc35;
  padding: 80px 50px;
  font-style: italic;
  border-radius: 8px;
}

.yellow .blog-area .item blockquote {
  background: #febc35;
}

.blog-area .item blockquote cite {
  display: block;
  margin-top: 9px;
  color: #ffffff;
  position: relative;
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  padding-left: 30px;
}

.blog-area .item blockquote cite::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  height: 2px;
  width: 20px;
  background: #ffffff;
}

.blog-area .item blockquote::after {
  position: absolute;
  right: 50px;
  content: "\f10d";
  font-family: "Font Awesome 5 Pro";
  bottom: 8px;
  font-weight: 100;
  opacity: 0.5;
  font-size: 140px;
  font-style: normal;
  color: #ffffff;
}

.blog-area .item blockquote p {
  margin: 0;
  position: relative;
  z-index: 1;
  line-height: 34px;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
}

.blog-area .blog-content .post-tags, 
.blog-area .blog-content .share {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0;
  padding-bottom: 6px;
}

.blog-area .blog-content .share li {
  display: inline-block;
  margin-left: 15px;
}

.blog-area .blog-content .share li a {
  display: inline-block;
  color: #febc35;
}

.yellow .blog-area .blog-content .share li a {
  color: #febc35;
}

.blog-area .blog-content .share li.facebook a {
  color: #3B5998;
}

.blog-area .blog-content .share li.twitter a {
  color: #1DA1F2;
}

.blog-area .blog-content .share li.pinterest a {
  color: #BD081C;
}

.blog-area .blog-content .share li.g-plus a {
  color: #DB4437;
}

.blog-area .blog-content .share li.linkedin a {
  color: #0077B5;
}

.blog-area .blog-content .post-tags .tags a {
  background: #eef1f6;
  padding: 10px 15px;
  font-size: 14px;
  margin-right: 3px;
  margin-bottom: 5px;
  display: inline-block;
}

.blog-area.single .post-pagi-area {
  margin-top: 50px;
  overflow: hidden;
}

.blog-area.single .post-pagi-area a {
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  color: #999999;
  border-radius: 30px;
}

.blog-area.single .post-pagi-area h5 {
  margin: 0;
  margin-top: 5px;
  font-weight: 900;
  transition: all 0.35s ease-in-out;
  font-size: 18px;
}

.blog-area.single .post-pagi-area h5:hover {
  color: #febc35;
}

.yellow .blog-area.single .post-pagi-area h5:hover {
  color: #febc35;
}

.blog-area.single.color-yellow .post-pagi-area a:hover {
  color: #ff9800;
}

.blog-area.single .post-pagi-area a:last-child {
  float: right;
}

.blog-area.single .post-pagi-area a:first-child i {
  margin-right: 3px;
}

.blog-area.single .post-pagi-area a:last-child i {
  margin-left: 3px;
}

.comments-area .commen-item .comments-info p {
  display: block;
  margin: 0;
}

.blog-area .contact-comments .submit {
  margin-bottom: 0;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
 /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Comments */

.blog-area .comments-form {
  margin-top: 50px;
}

.blog-area .blog-comments .comments-list {
  margin-bottom: 50px;
}

.blog-area .blog-comments .comments-list:last-child {
  margin-bottom: 0;
}

.comments-list .commen-item .avatar {
  height: 130px;
  width: 130px;
  min-width: 130px;
}

.comments-list .commen-item .content {
  position: relative;
}

.comments-list .commen-item .avatar img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.comments-list .commen-item {
  margin-top: 50px;
  display: flex;
  padding: 40px 30px;
  background: #ffffff;
  box-shadow: 0 0 25px rgb(0 0 0 / 8%);
  border-radius: 8px;
}

.comments-list .commen-item:first-child {
  margin: 0;
}

.comments-list .commen-item:last-child {
  margin-bottom: 0;
}

.comments-list .commen-item.reply {
  margin-left: 80px;
}

.comments-area {
  margin-top: 50px;
}

.comments-area .comments-title h2,
.comments-area .comments-title h3,
.comments-area .comments-title h4 {
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.comments-list .commen-item .content h3, 
.comments-list .commen-item .content h4, 
.comments-list .commen-item .content h5 {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0;
}

.comments-list .commen-item .content .reply a {
  font-size: 14px;
  background: #0a1015;
  color: #ffffff;
  display: inline-block;
  padding: 8px;
  font-weight: 600;
}

.comments-list .commen-item .content .reply a:hover {
  background: #febc35;
}

.yellow .comments-list .commen-item .content .reply a:hover {
  background: #febc35;
}

.comments-list .commen-item .content .reply i {
  font-size: 12px;
}

.comments-list .commen-item .content p {
  margin-bottom: 0;
}

.comments-list .commen-item .content .title {
  align-items: center;
  margin-bottom: 15px;
  font-weight: 500;
}

.comments-list .commen-item .content .title span {
  font-weight: 500;
  color: #999999;
  display: block;
  font-size: 15px;
  margin-top: 5px;
}

.comments-list .commen-item .content .title span i {
  font-weight: 500;
  margin-right: 5px;
}

.comments-list .commen-item .content .title span i.fa-reply {
  font-weight: 600;
  margin-right: 3px;
}

.comments-list .commen-item .content .title span a {
  margin-left: 10px;
}

.commen-item .comments-info a {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  border-radius: 30px;
  display: inline-block;
  padding: 3px 25px;
  background: #febc35;
  box-shadow: 0 0 25px rgb(0 0 0 / 8%);
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.yellow .commen-item .comments-info a {
  background: #febc35;
}

.comments-info a i {
  margin-right: 10px;
}

.comments-form input, 
.comments-form textarea {
  box-shadow: inherit;
  padding: 20px;
  background: transparent;
  min-height: 60px;
  border: 1px solid #e7e7e7;
  border-radius: inherit;
}

.comments-form textarea {
  min-height: 180px;
  padding: 15px;
}

.blog-area.full-blog.single-blog .form-group.submit {
  margin-bottom: 0;
}

.comments-form button {
  margin-top: 15px;
  background-color: #febc35;
  color: #ffffff !important;
  border: 2px solid #febc35;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  padding: 15px 40px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.yellow .comments-form button {
  background-color: #febc35;
  color: #232323 !important;
  border: 2px solid #febc35;
}

.comments-form button::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 0;
  transition: all 0.25s ease-in-out;
  background: #022147;
  z-index: -1;
}

.comments-form button:hover::after {
  width: 100%;
  height: 100%;
}

.comments-form button:hover {
  border: 2px solid #022147;
}

.comments-area .title h2, 
.comments-area .title h3, 
.comments-area .title h4 {
  font-weight: 600;
  margin-bottom: 25px;
  text-transform: capitalize;
  font-size: 26px;
}

.blog-area .contact-comments .comments {
  margin-top: 20px;
}

.blog-area.single .blog-items .item .contact-comments .col-md-6 {
  float: left;
  padding: 0 15px;
}


/* Pagination */

.pagination li {
  display: inline-block;
  margin-top: 7px;
}

ul.pagination {
  display: block;
  margin-top: 30px;
}

.pagination li a {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 0 2px;
  color: #febc35;
  font-weight: 800;
}

.yellow .pagination li a {
  color: #febc35;
}

.pagination li.page-item.active a {
  background: #febc35;
  border-color: #febc35;
}

.yellow .pagination li.page-item.active a {
  background: #febc35;
  border-color: #febc35;
}


/* ============================================================== 
     # Contact 
=================================================================== */

.contact-area {
  position: relative;
}

.contact-area .fixed-bg {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  text-align: right;
}

.contact-area .fixed-bg img {
  width: 60%;
  position: relative;
  right: 0;
  height: 100%;
  bottom: 0;
}

.contact-area .contact-items {
  background: #ffffff;
  padding: 40px;
  box-shadow: 0 0 25px rgb(0 0 0 / 8%);
  position: relative;
  bottom: -120px;
  margin-top: -120px;
  z-index: 9;
}

.contact-area .left-item {
  padding-right: 80px;
}

.contact-area .right-item {
  margin-top: 75px;
}

.contact-area .contact-items .right-item h2 {
  font-weight: 600;
  margin-bottom: 40px;
}

.contact-area .contact-items .item {
  display: flex;
  margin-bottom: 40px;
}

.contact-area .contact-items .item:last-child {
  margin-bottom: 0;
}

.contact-area .contact-items .item i {
  display: inline-block;
  font-size: 30px;
  background: rgba(255, 53, 20, 0.1);
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  color:#febc35;
  border-radius: 5px;
  margin-right: 25px;
  border: 1px solid rgba(255, 53, 20, 0.2);
}

.contact-area .contact-items .item p {
  margin: 0;
}

.contact-area .contact-items .item a {
  font-weight: 400;
  color: #666666;
}

.contact-area .contact-items .item a:hover {
  color: #febc35;
}

.yellow .contact-area .contact-items .item a:hover {
  color: #febc35;
}

.contact-area .contact-items .item h4 {
  margin-bottom: 10px;
  font-weight: 600;
}

.contact-area .contact-items .content h2 {
  font-weight: 600;
  margin-bottom: 30px;
}

.contact-area .contact-items .content input, 
.contact-area .contact-items .content textarea {
  border: none;
  padding: 20px;
  background: #eef1f6;
  min-height: 60px;
}

.contact-area .contact-items .content textarea {
  min-height: 220px;
}

.contact-area .contact-items .content button {
  background: #febc35;
  color: #ffffff;
  border: none;
  padding: 15px 40px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  text-align: center;
}

.yellow .contact-area .contact-items .content button {
  background: #febc35;
  color: #232323;
}

.contact-area .contact-items .content button::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 0;
  background: #022147;
  z-index: -1;
  transition: all 0.35s ease-in-out;
  border-radius: 5px;
}

.contact-area .contact-items .content button:hover::after {
  width: 100%;
  height: 100%;
}

.contact-area img.loader {
  margin-left: 10px;
}

/* Google Maps */

.google-maps iframe {
  border: medium none;
  height: 550px;
  margin-bottom: -10px;
  width: 100%;
}

.maps-area {
  overflow: hidden;
}

/* ============================================================== 
     # 404 
=================================================================== */

.error-page-area .error-box {
  position: relative;
  z-index: 1;
}

.error-page-area img {
  height: 300px;
  margin-bottom: 50px;
}

.error-page-area .error-box h1 {
  font-size: 300px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.07;
}

.error-page-area .error-box h2 {
  font-weight: 600;
  margin-top: -8px;
  font-size: 48px;
}

.error-page-area .error-box p {
  padding: 0 15%;
  margin-bottom: -5px;
}

.error-page-area .error-box a {
  margin-top: 20px;
}

.error-page-area .error-box form {
  position: relative;
  width: 60%;
  margin: 30px auto auto;
  border: 1px solid #e7e7e7;
  background: #ffffff;
}

.error-page-area .error-box form input {
  border: none;
  box-shadow: none;
  background: transparent;
}

.error-page-area .error-box form button {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  min-height: 50px;
  width: 50px;
  background: #febc35;
  color: #ffffff;
}

.yellow .error-page-area .error-box form button {
  background: #febc35;
  color: #232323;
}


/* ============================================================== 
     # Footer 
=================================================================== */

footer {
  position: relative;
  z-index: 1;
}

footer .f-items.default-padding {
  padding-top: 70px;
}

footer .fixed-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.05;
}

footer .fixed-shape img {
  width: 100%;
  position: absolute;
  bottom: 0;
}


/* Footer Common CSS */


footer .single-item {
  margin-top: 50px;
}

footer .widget-title {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 22px;
}

footer .contact {
  padding-right: 50px;
}

footer .contact ul li {
  margin-bottom: 15px;
}

footer .contact ul li a {
  font-weight: 400;
}

footer .contact ul li a:hover {
  color: #febc35;
}

.yellow footer .contact ul li a:hover {
  color: #febc35;
}

footer .contact ul {
  margin-top: 25px;
}

footer .contact ul li i {
  margin-right: 15px;
  min-width: 20px;
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  border: 1px solid #e7e7e7;
  line-height: 40px;
  color: #febc35;
}

.yellow footer .contact ul li i {
  color: #febc35;
}

footer.bg-dark .contact ul li i {
  border-color: rgba(255, 255, 255, 0.2);
  color: #febc35;
}

footer .contact ul li:last-child {
  margin-bottom: 0;
}

footer .link ul li {
  margin-bottom: 15px;
  padding-left: 15px;
  position: relative;
}

footer .link ul li::after {
  position: absolute;
  left: 0;
  top: 9px;
  content: "";
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #bcb3b3;
}

footer .link ul li:last-child {
  margin-bottom: 0;
}

footer .link ul li a {
  color: #666666;
  font-weight: 500;
}

footer.bg-dark .link ul li a {
  color: #cccccc;
}

footer .link ul li a:hover {
  color: #febc35;
}

.yellow footer .link ul li a:hover {
  color: #febc35;
}

footer .opening-hours li {
  margin-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

footer.bg-dark .opening-hours li {
  border-color: rgba(255, 255, 255, 0.2);
  color: #cccccc;
}

footer .opening-hours li:last-child {
  margin: 0;
  padding: 0;
  border: navajowhite;
}

footer .opening-hours li .closed {
  color: #ffffff;
  background:  #febc35;
  padding: 0 15px;
  border-radius: 5px;
}

.yellow footer .opening-hours li .closed {
  color: #232323;
  background: #febc35;
}

/* Footer Bottom  */
.footer-bottom {
  padding-bottom: 30px;
  margin-top: -10px;
}

.footer-bottom p {
  margin: 0;
}

.footer-bottom li {
  display: inline-block;
  margin-bottom: 0 !important;
}

.footer-bottom .text-right li {
  margin-left: 15px;
}

.footer-bottom .text-right li a {
  font-weight: 600;
}



/* ============================================================== 
     # Preloader 
=================================================================== */
.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: url(assets/img/preloader.gif) center no-repeat #fff;
  text-align: center;
}